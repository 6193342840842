import axios from 'axios'
import dayjs from 'dayjs'
import {useEffect, useState} from 'react'
import {STEP_SUMMARY_API} from '../../../helpers/Common'

import {Chart, Legend, TimeScale} from 'chart.js'
import 'chartjs-adapter-dayjs-4'
import {Bar} from 'react-chartjs-2'
import {useParams} from 'react-router-dom'
import {KTIcon} from '../../../../../_metronic/helpers/components/KTIcon'
import {StepMonthChart} from './StepMonthChart'

Chart.register(
    TimeScale,
    Legend,
)

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            callbacks: {
                label: function(context) {
                    let label: string = ''
                    switch (context.datasetIndex) {
                        case 0: {
                            label = `${context.raw.y} bước`
                            break
                        }
                    }
                    return label
                },
            },
        },
    },
    scales: {
        x: {
            type: 'time' as const,
            time: {
                unit: 'day' as const,
                displayFormats: {
                    day: 'D' as const,
                },
                round: 'day' as const,
                tooltipFormat: 'DD-MM-YYYY' as const,
            },
            title: {
                display: false,
            },
            ticks: {
                maxRotation: 0,
                minRotation: 0,
                autoSkip: false,
            },
            y: {
                min: 0,
            },
        },
    },
}

const StepWidget = ({className, uid}) => {
    let initData: any[] = []
    const [series, setSeries] = useState({datasets: initData})
    const [date] = useState(dayjs())
    const [showModal, setShowModal] = useState(false)
    const [modalWidget, setModalWidget] = useState(<StepMonthChart uid={uid}
                                                                   className="card-xxl-stretch mb-5 mb-xl-10" />)
    const {uid: uid_} = useParams()
    // const handleBack = () => {
    //     setDate(date.subtract(1, 'month'))
    // }

    // const handleNext = () => {
    //     setDate(date.add(1, 'month'))
    // }

    // const handleDateSelect = (date, dateString) => {
    //     setDate(date)
    // }

    useEffect(() => {
        axios.get(STEP_SUMMARY_API, {
            params: {
                local_year: date.year(),
                local_month: date.month() + 1,
                target_user_id: uid_,
            },
        })
            .then(response => {
                // console.log('step', date.date())
                let dateArr = new Array(date.endOf('month').date()).fill(0)
                response.data.data.forEach(item => {
                    dateArr[dayjs(item.ref_time).date() - 1] = item.total_steps
                })
                let data = dateArr.map((item, index) => {
                    return {
                        x: dayjs(date).date(index + 1).valueOf(),
                        y: item,
                    }
                })
                // data = data.filter(value => { return value.y })
                // console.log(data)
                // let data = response.data.data.map(item => {
                //     return {
                //         x: dayjs(item.ref_time).valueOf(),
                //         y: item.total_steps
                //     }
                // })
                setSeries({
                    datasets: [{
                        data: data,
                        borderRadius: Number.MAX_VALUE,
                        // borderSkipped: false,
                        categoryPercentage: 0.4,
                        backgroundColor: '#3E97FF',
                    }],
                })
            })
            .catch(error => console.log(error))
    }, [date, uid_])


    useEffect(() => {
        if (showModal) {
            setModalWidget(<StepMonthChart uid={uid} className="card-xxl-stretch mb-5 mb-xl-10" />)
        } else {
            setModalWidget(<></>)
        }
    }, [showModal, uid])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">Bước chân</span>
                    <span
                        className="text-muted fw-semibold fs-7">{date.startOf('month').format('DD/MM/YYYY')} - {date.endOf('month').format('DD/MM/YYYY')}</span>
                </h3>

                {/* begin::Toolbar */}
                <div className="card-toolbar" data-kt-buttons="true">
                    <button
                        type="button"
                        className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                        data-bs-toggle="modal" data-bs-target="#kt_modal_1"
                        onClick={() => {
                            setShowModal(true)
                        }}
                    >
                        <KTIcon iconName="category" className="fs-2" />
                    </button>

                    <div className="modal modal-xl fade" tabIndex={-1} id="kt_modal_1">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                {/* <div className="modal-header">
                                    <h3 className="modal-title">Modal title</h3>
                                    <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                        <i className="ki-duotone ki-cross fs-1"><span className="path1"></span><span className="path2"></span></i>
                                    </div>
                                </div> */}

                                <div className="modal-body">
                                    {modalWidget}
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn"
                                            style={{color: '#FFFFFF', backgroundColor: '#3e97ff'}}
                                            data-bs-dismiss="modal" onClick={() => {
                                        setShowModal(false)
                                    }}>Đóng
                                    </button>
                                    {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Space>
                        <Button type="default" onClick={handleBack}>Trước</Button>
                        <DatePicker value={date} picker="month" onChange={handleDateSelect} allowClear={false} />
                        <Button type="default" onClick={handleNext}>Sau</Button>
                    </Space> */}
                </div>
                {/* end::Toolbar */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className="card-body" style={{height: '300px'}}>
                <Bar
                    options={options}
                    data={series}
                />
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export {StepWidget}
