import { Avatar, Col, Image, Row, Space, Typography, Tooltip } from "antd"
import Link from "antd/es/typography/Link"
import { MEDIA_CONVERT_URL, getChatTimeDescription } from "app/modules/helpers/Common"
import axios from "axios"
import clsx from "clsx"
import dayjs from "dayjs"
import { useEffect, useState } from "react"

const { Text } = Typography

const MessageContent = ({ message, isFirst, isLast, isSelf }) => {
  const [url, setUrl] = useState([] as any[])

  useEffect(() => {
    if (message.type_message === 'image') {
      axios.get(MEDIA_CONVERT_URL, { params: { list_path: JSON.stringify(message.message.attachments.url), chatlog_id: message.uid } })
        .then((response) => {
          setUrl(response.data.data)
        })
    }
  }, [message])

  if (message.type_message === 'text') {
    return (
      <div
        className={clsx('px-4', 'py-2', 'mb-1', 'mw-75')}
        style={{
          borderTopLeftRadius: isFirst || isSelf ? '1.5rem' : '0.2rem',
          borderTopRightRadius: isFirst || !isSelf ? '1.5rem' : '0.2rem',
          borderBottomLeftRadius: isLast || isSelf ? '1.5rem' : '0.2rem',
          borderBottomRightRadius: isLast || !isSelf ? '1.5rem' : '0.2rem',
          backgroundColor: isSelf ? '#4e4bf5' : '#eee',
          color: isSelf ? '#fff' : '#000',
        }}
      >
        {message.message.text}
      </div>
    )
  } else if (message.type_message === 'file') {
    return (
      <Link
        className={clsx('px-4', 'py-2', 'mb-1', 'mw-75')}
        style={{
          borderTopLeftRadius: isFirst || isSelf ? '1.5rem' : '0.2rem',
          borderTopRightRadius: isFirst || !isSelf ? '1.5rem' : '0.2rem',
          borderBottomLeftRadius: isLast || isSelf ? '1.5rem' : '0.2rem',
          borderBottomRightRadius: isLast || !isSelf ? '1.5rem' : '0.2rem',
          backgroundColor: '#ccc',
          color: '#000',
        }}
        onClick={() => {
          axios.get(MEDIA_CONVERT_URL, { params: { list_path: JSON.stringify(message.message.attachments.url), chatlog_id: message.uid } })
            .then((response) => {
              window.open(response.data.data[0], '_blank')
            })
        }}
      >
        <Space>
          <Avatar size={32} icon={<i className="fa-solid fa-paperclip"></i>} />
          {message.message.text || 'unknownfile.pdf'}
        </Space>
      </Link>
    )
  } else if (message.message.attachments?.url.length === 1) {
    return (
      <div className='mw-75'>
        <Image
          className={clsx('mb-1')}
          style={{
            borderTopLeftRadius: isFirst || isSelf ? '1.5rem' : '0.2rem',
            borderTopRightRadius: isFirst || !isSelf ? '1.5rem' : '0.2rem',
            borderBottomLeftRadius: isLast || isSelf ? '1.5rem' : '0.2rem',
            borderBottomRightRadius: isLast || !isSelf ? '1.5rem' : '0.2rem',
            height: 200,
            objectFit: 'cover',
            aspectRatio: '1/1',
            border: '.1px solid #eee'
          }}
          src={url.length > 0 ? url[0] : 'error'}
        />
      </div>
    )
  } else {
    const span = url.length === 2 ? 12 : 8
    return (
      <div className='mw-75 mb-1'>
        <Row>
          {url.map((image_url, index) => (
            <Col span={span} className={clsx({ "ms-auto": isSelf && index % 3 === 0 })} key={index}>
              <Image
                style={{
                  borderRadius: '0.2rem',
                  maxHeight: 200,
                  objectFit: 'cover',
                  aspectRatio: '1/1',
                  border: '.1px solid #eee'
                }}
                src={image_url}
              />
            </Col>
          ))}
        </Row>
      </div>
    )
  }
}

export const SystemBubble = ({ message }) => {
  return (
    message.meta_data.appointment_id &&
    <div
      className={clsx('px-4', 'py-2', 'm-5', 'mw-100', 'mx-auto')}
      style={{
        borderRadius: '0.5rem',
        backgroundColor: '#e6f4ff',
        color: '#000',
        width: 'max-content'
      }}
    >
      <Space >
        <Avatar size={32} icon={<i className="fa-regular fa-calendar-check"></i>} style={{ backgroundColor: '#1677ff' }} />
        <Space direction='vertical' size={0} style={{ marginRight: 20 }}>
          <Text>{dayjs(message.meta_data.start_time * 1000).format('DD-MM-YYYY')}</Text>
          <Text>{dayjs(message.meta_data.start_time * 1000).format('HH:mm')}</Text>
        </Space>
        <Space direction='vertical' size={0} style={{ marginRight: 20 }}>
          <Text>Lịch khám đã thiết lập</Text>
          <Tooltip
            title={message.meta_data.title}
          >
            <Text
              style={{ maxWidth: '140px' }}
              ellipsis={true}
            >
              <Link>{message.meta_data.title}</Link>
            </Text>
          </Tooltip>
        </Space>
      </Space>
    </div>
  )
}

export const MessageBubble = ({ message, user, isLast, isFirst, isSelf, markTime = true }) => {
  // const isSelf = user.isSelf
  const showAvatar = isFirst
  const currentUser = user.get(message.sender_id)
  const order = isSelf ? 'row-reverse' : 'row'
  if (message.role === 'SYSTEM') {
    return <SystemBubble message={message} />
  } else if (message.role === 'LOG_HISTORY') {
    return <></>
  }

  return (
    <>
      {markTime && <Row>
        <Text type="secondary" className="fs-7 mx-auto pt-7 pb-3">{getChatTimeDescription(message.created_at)}</Text>
      </Row>}
      {isFirst && <Row style={{ width: '100%', flexDirection: order, paddingInline: 42 }}>
        <Text type="secondary" className="fs-7">{currentUser.name} {dayjs(message.created_at * 1000).format('HH:mm')}</Text>
      </Row>}
      <Row style={{ width: '100%', flexDirection: order }}>
        {showAvatar ? (
          <Avatar size={32} src={currentUser.avatar || undefined} style={{ marginInline: 5 }}>
            {currentUser.name.charAt(0)}
          </Avatar>
        ) : (
          <div style={{ width: 42 }} />
        )}
        <MessageContent message={message} isFirst={isFirst} isLast={isLast} isSelf={isSelf} />
      </Row>
    </>
  )
}