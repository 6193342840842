import {KTIcon} from '_metronic/helpers'
import {Button, Card, Col, DatePicker, Dropdown, Row, Segmented, Space, Spin, theme, Typography} from 'antd'
import {APPOINTMENT_API, DOCTOR_SHIFT_API, getLongDateDescription, WORKING_TIME_API} from 'app/modules/helpers/Common'
import axios from 'axios'

import dayjs from 'dayjs'
import isoweek from 'dayjs/plugin/isoWeek'
import weekday from 'dayjs/plugin/weekday'

import {useEffect, useReducer, useRef, useState} from 'react'
import {Rnd} from 'react-rnd'
import {PlanModal} from './PlanModal'

const {Text} = Typography
const {useToken} = theme
const cellHeight = 80
dayjs.extend(weekday)
dayjs.extend(isoweek)

const ShiftBlock = ({shift, updatePlan, removePlan, mode}) => {
  const [y, setY] = useState(cellHeight * dayjs(shift.start * 1000).hour() + dayjs(shift.start * 1000).minute() * 4 / 3)
  const [height, setHeight] = useState((shift.end - shift.start) / 60 / 60 * cellHeight)
  const [startTime, setStartTime] = useState(dayjs(shift.start * 1000))

  const getSameDayEndTime = (shift) => {
    const sd = dayjs.unix(shift.start).format('YYYY/MM/DD')
    const ed = dayjs.unix(shift.end).format('YYYY/MM/DD')

    let res = dayjs.unix(shift.end)
    if (sd !== ed) {
      res = dayjs(`${sd} 23:59:59`, 'YYYY/MM/DD HH:mm:ss')
    }

    return res
  }
  const [endTime, setEndTime] = useState(getSameDayEndTime(shift))

  const isPast = dayjs() > dayjs.unix(shift.end)

  const blockWidth = mode === 'show' ? '50%' : '100%'

  return (
    <Rnd
      default={
        {
          x: 0,
          y: cellHeight * dayjs(shift.start * 1000).hour() + dayjs(shift.start * 1000).minute() * 4 / 3,
          width: blockWidth,
          height: (shift.end - shift.start) / 60 / 60 * cellHeight,
        }
      }
      size={{width: blockWidth, height: height}}
      position={{x: 0, y: y}}
      bounds="parent"
      onResizeStop={(e, direction, ref, delta, position) => {
        let newY = y
        if (direction === 'top') {
          setY(y => y - delta.height)
          newY = y - delta.height
        }
        setHeight(ref.offsetHeight)
        const newHeight = ref.offsetHeight
        updatePlan({
          shift: shift,
          start: dayjs(shift.start * 1000).startOf('day').second(newY * 60 * 60 / cellHeight).unix(),
          end: dayjs(shift.start * 1000).startOf('day').second((newY + newHeight) * 60 * 60 / cellHeight).unix(),
        })
      }}

      resizeGrid={[1, 20]}
      enableResizing={{
        top: !isPast,
        bottom: !isPast,
      }}
      disableDragging

      style={{
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      // zIndex={0}
    >
      {/* Lịch làm việc */}
      <Text className="fs-8 text-primary" style={{
        position: 'absolute',
        top: -15,
      }}>{dayjs().startOf('day').second(y * 60 * 60 / cellHeight).format('HH:mm')}</Text>
      <Text className="fs-8 text-white" style={{
        position: 'absolute',
        bottom: 0,
      }}>{dayjs().startOf('day').second((y + height) * 60 * 60 / cellHeight).format('HH:mm')}</Text>
      {/* <Text className="fs-8 text-primary" style={{ position: 'absolute', bottom: -20 }}>{dayjs().startOf('day').second((y + height) * 60 * 60 / cellHeight).format('HH:mm')}</Text> */}
      {/* <Text className="fs-8 text-gray-600" style={{ position: 'absolute', top: -20 }}>{dayjs().startOf('day').second(y * 60 * 60 / cellHeight).format('HH:mm')}</Text> */}
      <Dropdown
        trigger={['contextMenu', 'click']}
        destroyPopupOnHide
        onOpenChange={(open) => {
          if (!open) {
            setStartTime(dayjs(shift.start * 1000))
            setEndTime(dayjs(shift.end * 1000))
          }
        }}
        // getPopupContainer={trigger => trigger.parentElement as any}
        dropdownRender={menu => (
          <Card size="small"
                title={<Text className="fs-6 text-primary">{getLongDateDescription(shift.start)}</Text>}
                actions={[<Button danger type="primary" icon={<i className="fa-solid fa-trash-can"></i>}
                                  onClick={() => removePlan({shift})}>Xoá lịch</Button>]}>
            <Space direction="vertical">
              <Space>
                <DatePicker.RangePicker
                  style={{maxWidth: 160}}
                  allowClear={false}
                  allowEmpty={[false, false]}
                  showSecond={false}
                  minuteStep={15}
                  picker="time"
                  needConfirm={false}
                  value={[startTime, endTime]}
                  onChange={(dates: any) => {
                    setStartTime(dates[0])
                    setEndTime(dates[1])
                  }}
                />
                <Button
                  onClick={() => updatePlan({shift, start: startTime.unix(), end: endTime.unix()})}
                >Lưu</Button>
              </Space>
              {/* <Button icon={<i className="fa-solid fa-trash-can"></i>}>Xoá lịch</Button> */}
            </Space>
          </Card>
        )
        }
        //  placement=''
      >
        <div style={{
          width: '100%',
          height: '100%',
          border: 'solid 1px #ddd',
          background: '#69b1ff',
          marginRight: 1,
          boxSizing: 'border-box',
          borderRadius: '0.2rem',
        }}>
          {/* <Text className="fs-8 text-gray-600">{dayjs().startOf('day').second(height * 60 * 60 / cellHeight).format('HH:mm')}</Text> */}
          {/* <Button size="small">Xoá</Button> */}
        </div>
      </Dropdown>
    </Rnd>
  )
}

// const CalendarBlock = ({ hour, index, addPlan }) => {
//   const [isHover, setIsHover] = useState(false)

//   return (
//     <Row style={{ height: cellHeight }} className="border-top border-end"
//       onMouseEnter={(e: any) => { setIsHover(true); console.log('isHover'); e.stopPropagation() }}
//       onMouseLeave={(e: any) => setIsHover(false)}
//       onClick={(e) => {
//         setIsHover(false)
//         console.log('isHover false')
//         const blockTime = dayjs().weekday(index).startOf('day').hour(hour).unix()
//         // const newPlan = [...plan, { start: blockTime, end: blockTime + 3600 }]
//         // setPlan(newPlan)
//         console.log('blockTime', blockTime)
//         addPlan({ start: blockTime, end: blockTime + 3600 })
//         // setPlan(plan => plan.push({ start: blockTime, end: blockTime + 3600 }))
//         e.stopPropagation()
//       }}
//     >
//       {isHover &&
//         <div style={{ position: 'absolute', width: '100%', height: '80px', justifyContent: 'center', textAlign: 'center', justifyItems: 'center', padding: 10, zIndex: 0 }} 
//         >
//           <Space direction="vertical" size={0} style={{ width: '100%', background: 'b7eb8f', border: "dashed 1px #009ef7", zIndex: 0 }} >
//             <KTIcon iconName="calendar-add" className="fs-2x text-primary mt-2" />
//             <Text className="fs-7 text-primary">Thêm lịch</Text>
//           </Space>

//         </div>}
//     </Row>)
// }

const CalendarWeek = ({appointments, currentDate, plan, updatePlan, addPlan, mode, remotePlan, counter}) => {
  const {token} = useToken()
  const calendarRef = useRef(undefined as any)

  const currentTime = useRef({} as any)

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (calendarRef.current) {
  //       if (dayjs().isAfter(currentDate.weekday(0).startOf('day')) && dayjs().isBefore(currentDate.weekday(6).endOf('day'))) {
  //         calendarRef.current.scrollTo({ top: cellHeight * (dayjs().hour() > 3 ? dayjs().hour() - 3 : 0), behavior: 'smooth' })
  //       } else {
  //         const minHour = Math.min(...appointments.map(appointment => dayjs(appointment.start_time * 1000).hour()))
  //         calendarRef.current.scrollTo({ top: cellHeight * minHour, behavior: 'smooth' })
  //       }
  //     }
  //   })
  // }, [appointments, currentDate])

  useEffect(() => {
    if (currentTime?.current) {
      currentTime?.current.scrollIntoView()
    }
  }, [])

  return (
    <>
      <Row className="w-100 border-bottom">
        <Col flex="50px"></Col>
        {Array.from(Array(7).keys()).map((index) =>
          <Col key={index} flex={1} style={{flexBasis: 0}}>
            <Row className="mx-auto border-end my-1" justify="center" align="middle">
              <Space direction="vertical" align="center" size={0} style={{lineHeight: '18px'}}>
                <Text style={{fontSize: 10}} strong>{currentDate.weekday(index).format('dddd').toUpperCase()}</Text>
                <Text strong style={{color: token.colorPrimaryHover}}>{currentDate.weekday(index).format('DD')}</Text>
              </Space>
            </Row>
          </Col>,
        )}
      </Row>

      <Row className="w-100" style={{height: '100%', overflowY: 'scroll', marginTop: -1, scrollbarWidth: 'none'}}
           ref={calendarRef}>
        <Col flex="50px">
          {/* <Row style={{ height: cellHeight }} className="border-top" /> */}
          {Array.from(Array(24).keys()).map((hour) =>
            <Row key={hour} style={{height: cellHeight}} justify="end" className="border-top border-start border-end">
              <span className="fs-8 text-gray-600 me-2">{dayjs().startOf('hour').hour(hour).format('HH:mm')}</span>
            </Row>)}
        </Col>
        {Array.from(Array(7).keys()).map((index) =>
          <Col key={index} flex={1}>
            {mode === 'show' && appointments.filter((appointment) => dayjs(appointment.start_time * 1000).weekday() === index)
              .map(appointment =>
                <div
                  key={appointment.uid}
                  style={{
                    position: 'absolute',
                    top: cellHeight * dayjs(appointment.start_time * 1000).hour() + 3 + dayjs(appointment.start_time * 1000).minute() * 4 / 3,
                    width: '50%',
                    left: '50%',
                    height: 20,
                    // background: 'repeating-linear-gradient(-45deg, #ccc, #ccc 5px, #aaa 5px, #aaa 10px)'
                    // backgroundColor: '#bae637'
                  }}
                  role="button"
                >
                  <div style={{
                    // background: 'repeating-linear-gradient(-45deg, #ccc, #ccc 5px, #aaa 5px, #aaa 10px)',
                    // backgroundColor: '#eee',
                    backgroundColor: '#d9f7be',
                    borderRadius: '1px',
                    marginInline: 5,
                    paddingInline: 3,
                    lineHeight: '15px',
                    // maxWidth: '100%',
                    width: 'calc(100% - 10px)',
                    height: 'calc(100% - 4px)',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                    <div style={{
                      width: '4px',
                      height: '10px',
                      background: token.colorPrimary,
                      borderRadius: '4px',
                      marginRight: '3px',
                      display: 'inline-block',
                    }}></div>
                    <span className="fs-8 text-gray-600" style={{
                      height: '15px',
                      width: '45px',
                      display: 'inline-block',
                    }}> {dayjs(appointment.start_time * 1000).format('HH:mm')}</span>
                    {/* <span className="fs-8 text-truncate text-gray-600" style={{ height: '15px', display: 'inline-block', width: '100%' }}>{appointment.patient_info.fullname}</span> */}
                  </div>
                </div>,
              )}
            {plan.filter((item) => dayjs(item.start * 1000).weekday() === index).map((item, index) => <ShiftBlock
              key={`${item.start}-${item.end}-${counter}`} shift={item} updatePlan={updatePlan} removePlan={remotePlan}
              mode={mode} />)}

            {Array.from(Array(24).keys()).map((hour) =>
              // <CalendarBlock hour={hour} index={index} addPlan={addPlan} key={hour} />)}
              <Row key={hour} style={{height: cellHeight}} className="border-top border-end"
                   onMouseEnter={(e: any) => e.target.style.background = '#eee'}
                   onMouseLeave={(e: any) => e.target.style.background = 'white'}
                   onClick={() => {
                     const blockTime = currentDate.weekday(index).startOf('day').hour(hour).unix()
                     addPlan({start: blockTime, end: blockTime + 3600})
                   }}
              >
              </Row>)
            }
            {index === dayjs().weekday() && dayjs().isAfter(currentDate.weekday(0).startOf('day')) && dayjs().isBefore(currentDate.weekday(6).endOf('day')) &&
              <div style={{
                height: 2,
                position: 'absolute',
                width: '100%',
                top: cellHeight * dayjs().hour() + 3 + dayjs().minute() * 4 / 3,
                backgroundColor: `#f005`,
              }}
                   className="border-top border-end"
                   ref={currentTime}
              >
              </div>}
          </Col>,
        )}
      </Row>
    </>
  )
}

export const WorkCalendar = () => {
  const [appointments, setAppointments] = useState([] as any[])
  const [currentDate, setCurrentDate] = useState(dayjs())
  const [plan, setPlan] = useState([] as any[])
  const [counter, forceUpdate] = useReducer(x => x + 1, 0)
  const [mode, setMode] = useState('show')

  const [showModal, setShowModal] = useState(false)

  const [spinning, setSpinning] = useState(false)
  const syncAppointment = (page, data: []) => {
    let from_date = currentDate.startOf('week').format('YYYY/MM/DD HH:mm:ss')
    let to_date = currentDate.endOf('week').format('YYYY/MM/DD HH:mm:ss')

    if (mode === 'date') {
      from_date = currentDate.startOf('day').format('YYYY/MM/DD HH:mm:ss')
      to_date = currentDate.endOf('day').format('YYYY/MM/DD HH:mm:ss')
    }
    setSpinning(true)
    setAppointments(data)
    axios.get(APPOINTMENT_API, {
      params: {
        page: page,
        per_page: 1000,
        from_datetime: from_date,
        to_datetime: to_date,
      },
    }).then(response => {
      if (page % 2 === 0 && page < response.data.data.max_page) {
        setAppointments([...data, ...response.data.data.items])
      }
      if (page < response.data.data.max_page) {
        // @ts-ignore
        syncAppointment(page + 1, [...data, ...response.data.data.items])
      } else {
        setAppointments([...data, ...response.data.data.items])
        setSpinning(false)
      }
    })
  }

  useEffect(() => {
    syncAppointment(1, [])

    axios.get(DOCTOR_SHIFT_API, {
      params: {
        local_week_num: currentDate.isoWeek(),
        local_year: currentDate.isoWeekYear(),
      },
    }).then(response => {

      let plans: any[] = []
      response.data.data.forEach(item => {
        plans.push(...item.working_time)
      })
      setPlan(plans)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, counter])

  const requestUpdate = (newPlan, local_date: string | undefined = undefined) => {
    const batch_time = newPlan.map(item => ({
      local_start_datetime: dayjs(item.start * 1000).format('YYYY/MM/DD HH:mm:ss'),
      local_end_datetime: dayjs(item.end * 1000).format('YYYY/MM/DD HH:mm:ss'),
    }))
    axios.post(WORKING_TIME_API, {batch_time, local_date}).then(() => {
      forceUpdate()
    })
  }

  const group = (arr, key = e => e) => {
    let grouped = [[]]

    if (arr.length) {

      const [first, ...rem] = arr

      grouped = rem.reduce((acc, v) => {

        let res = [...acc]

        const kv = key(v)

        const butLast = acc.slice(0, -1)
        const last = acc[acc.length - 1]

        const [firstOfLast] = last

        if (kv === key(firstOfLast)) {

          res = [...butLast, [...last, v]]
        } else {
          res = [...acc, [v]]
        }

        return res

      }, [[first]])
    }
    return grouped
  }

  const addPlan = ({start, end}) => {
    const notOverlap = plan.filter(item => !(start >= item.start && start <= item.end) && !(end >= item.start && end <= item.end))
    const overlap = plan.filter(item => (start >= item.start && start <= item.end) || (end >= item.start && end <= item.end))
    overlap.push({start, end})

    /*
    / Group overlap list by start date
    /*/
    const groupedOverlap = group(overlap, v => dayjs.unix(v.start).format('YYYY/MM/DD'))

    const extraPlan = groupedOverlap.flatMap(arr => {
      const minStart = Math.min(...arr.map((item: any) => item.start))
      const maxEnd = Math.max(...arr.map((item: any) => item.end))

      return {start: minStart, end: maxEnd}
    })

    let newPlan: any[] = notOverlap.map(item => ({start: item.start, end: item.end}))
    newPlan = [...newPlan, ...extraPlan]

    requestUpdate(newPlan.filter(item => dayjs(item.start * 1000).weekday() === dayjs(start * 1000).weekday()))
  }

  const updatePlan = ({shift, start, end}) => {
    const newPlan = plan.map(item => ({start: item.start, end: item.end}))
    const index = newPlan.findIndex(item => item.start === shift.start && item.end === shift.end)
    newPlan.splice(index, 1)

    const notOverlap = newPlan.filter(item => !(start >= item.start && start <= item.end) && !(end >= item.start && end <= item.end))
    const overlap = newPlan.filter(item => (start >= item.start && start <= item.end) || (end >= item.start && end <= item.end))
    overlap.push({start, end})

    /*
    / Group overlap list by start date
    /*/
    const groupedOverlap = group(overlap, v => dayjs.unix(v.start).format('YYYY/MM/DD'))

    const extraPlan = groupedOverlap.flatMap(arr => {
      const minStart = Math.min(...arr.map((item: any) => item.start))
      const maxEnd = Math.max(...arr.map((item: any) => item.end))

      return {start: minStart, end: maxEnd}
    })

    const updatedPlan = [...notOverlap, ...extraPlan].filter(item => dayjs(item.start * 1000).weekday() === dayjs(start * 1000).weekday())
    requestUpdate(updatedPlan)
  }

  const removePlan = ({shift}) => {
    const newPlan = plan.map(item => ({start: item.start, end: item.end}))
    const index = newPlan.findIndex(item => item.start === shift.start && item.end === shift.end)
    newPlan.splice(index, 1)
    requestUpdate(newPlan.filter(item => dayjs(item.start * 1000).weekday() === dayjs(shift.start * 1000).weekday()), dayjs(shift.start * 1000).format('YYYY/MM/DD'))
  }

  const copyPlan = () => {
    const lastWeek = currentDate.add(-1, 'week')
    axios.get(DOCTOR_SHIFT_API, {
      params: {
        local_week_num: lastWeek.isoWeek(),
        local_year: lastWeek.isoWeekYear(),
      },
    }).then(response => {
      let requests: any = []
      response.data.data.forEach(plans => {
        const batch_time = plans.working_time.map(item => ({
          local_start_datetime: dayjs(item.start * 1000).add(7, 'day').format('YYYY/MM/DD HH:mm:ss'),
          local_end_datetime: dayjs(item.end * 1000).add(7, 'day').format('YYYY/MM/DD HH:mm:ss'),
        }))
        requests.push(axios.post(WORKING_TIME_API, {
          batch_time,
          local_date: dayjs(plans.date, 'YYYY/MM/DD').add(7, 'day').format('YYYY/MM/DD'),
        }))
      })
      Array.from(Array(7).keys()).forEach(index => {
        if (response.data.data.findIndex(item => dayjs(item.date, 'YYYY/MM/DD').weekday() === index) === -1) {
          requests.push(axios.post(WORKING_TIME_API, {
            batch_time: [],
            local_date: currentDate.weekday(index).format('YYYY/MM/DD'),
          }))
        }
      })
      axios.all(requests).then(() => {
        forceUpdate()
      })
    })
  }

  return (
    <Card style={{padding: 0}}
          styles={{
            body: {
              padding: 0,
              maxHeight: '100%',
              height: 'calc(100vh - 255px)',
              display: 'flex',
              flexDirection: 'column',
            },
          }}
          extra={
            <Space>
              <Spin spinning={spinning} fullscreen></Spin>
              {/* {mode === 'show' &&
            <><i className="fa-solid fa-square" style={{color: '#d9f7be'}}></i> Lịch hẹn
            </>} */}
              Hiển thị lịch hẹn
              <Segmented options={[{value: 'show', icon: <i className="fa-solid fa-eye"></i>}, {
                value: 'hide',
                icon: <i className="fa-solid fa-eye-slash"></i>,
              }]} value={mode} onChange={(value) => setMode(value)} />
            </Space>}
          title={
            <Space>
              <Button onClick={() => setCurrentDate(dayjs())}>Hôm nay</Button>
              <Button icon={<i className="fa-solid fa-angle-left"></i>} onClick={() => {
                setCurrentDate(currentDate.add(-1, 'week'))
              }} />
              <DatePicker picker="week" format={'w-YYYY'} value={currentDate} onChange={(date) => setCurrentDate(date)}
                          allowClear={false} />
              <Button icon={<i className="fa-solid fa-angle-right"></i>} onClick={() => {
                setCurrentDate(currentDate.add(1, 'week'))
              }} />
              {currentDate.endOf('week').isAfter(dayjs()) && <Button onClick={copyPlan}>Sao chép tuần trước</Button>}
              <Button icon={<KTIcon iconName="calendar-add" className="fs-3 text-primary" />}
                      onClick={() => setShowModal(true)}>Thêm lịch</Button>
            </Space>
          }
    >
      <CalendarWeek appointments={appointments} currentDate={currentDate} plan={plan} updatePlan={updatePlan}
                    addPlan={addPlan} mode={mode} remotePlan={removePlan} counter={counter} />
      <PlanModal open={showModal} close={(update = false) => {
        setShowModal(false)
        if (update) {
          forceUpdate()
        }
      }} />
    </Card>
  )
}
