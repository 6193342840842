import React, {useEffect, useState} from 'react'
import {BLOODPRESSURE_DETAIL_API} from '../../../helpers/Common'
import axios from 'axios'
import dayjs from 'dayjs'
import {Card, ConfigProvider, DatePicker, Modal, Space, Table} from 'antd'
import Column from 'antd/es/table/Column'
import {FilePreview} from '../calendar/calendar'

const BloodPressureDetailModal = ({className, openDetailModal, closeDetailModal, uid}) => {
    const [dataSet, setDataSet] = useState([])
    const [date, setDate] = useState(dayjs())

    const handleDateSelect = (date) => {
        setDate(date)
    }

    useEffect(() => {
        axios.get(BLOODPRESSURE_DETAIL_API, {
            params: {
                target_user_id: uid,
                from_datetime: date.subtract(1, 'day').format('YYYY/MM/DD 17:00:00'),
                to_datetime: date.format('YYYY/MM/DD 16:59:59'),
            },
        })
            .then(response => {
                setDataSet(response.data.data)
            })
            .catch(error => console.log(error))
    }, [uid, date])

    return (
        <div className={`card ${className}`}>
            <Modal
                className={'w-700px'}
                open={openDetailModal}
                onOk={closeDetailModal}
                onCancel={closeDetailModal}
                okText={'Đóng'}
                cancelButtonProps={{style: {display: 'none'}}}
            >
                <DatePicker className={'mb-5'} value={date} onChange={handleDateSelect} allowClear={false} />
                <Card>
                    <ConfigProvider
                        theme={{
                            components: {
                                Table: {
                                    headerBg: '#fff',
                                },
                            },

                        }}
                    >
                        <Table
                            dataSource={dataSet}
                            rowKey="uid"
                            onHeaderRow={() => ({className: 'fw-bolder fs-7'})}
                            onRow={() => ({className: 'fs-7 text-gray-600'})}
                            size="small"
                            locale={{emptyText: 'Không có dữ liệu'}}
                            pagination={false}
                        >
                            <Column
                                align={'center'}
                                title="Thời gian đo"
                                dataIndex="measurement_datetime"
                                render={(value) => dayjs(value * 1000).format('HH:mm')}
                            />
                            <Column
                                align={'center'}
                                title="Huyết áp(mmHg)"
                                render={(_, value: any) => `${value.systolic || '-'}/${value.diastolic || '-'}`}
                            />
                            <Column
                                align={'center'}
                                title="Nhịp tim (nhịp/phút)"
                                dataIndex={'heart_rate'}
                                render={value => (value && value > 0) ? value : '-'}
                            />
                            <Column
                                align={'center'}
                                title="Hình ảnh"
                                render={(_, record: any) =>
                                    (record.image_path ?
                                            <Space>
                                                <FilePreview url={record.image_path} text={`Xem`}></FilePreview>
                                            </Space>
                                            : '-'
                                    )
                                }
                            />
                        </Table>
                    </ConfigProvider>
                </Card>
            </Modal>
        </div>
    )
}

export {BloodPressureDetailModal}
