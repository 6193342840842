import { Button, Col, Popover, Row, Space, Typography, theme, Tooltip } from "antd"
import dayjs from "dayjs"
import weekday from 'dayjs/plugin/weekday'
import { useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"

const { Text, Title } = Typography
const { useToken } = theme
dayjs.extend(weekday)

const AppointmentInfo = ({ appointment }) => {
  const navigate = useNavigate()

  return (
    <div style={{ minWidth: 500, display: 'flex', alignItems: 'center' }}>
      <div className="border bg-primary text-center rounded me-4" style={{ height: '100%', minWidth: 100, minHeight: 100, alignContent: 'center' }}>
        <Space direction="vertical" className="text-white fs-6 fw-bold" size={0}>
          {dayjs(appointment.start_time * 1000).format('dddd').toUpperCase()}
          <Text className="text-white fs-4 fw-bold">{dayjs(appointment.start_time * 1000).format('DD')}</Text>
        </Space>
      </div>
      <div className="bg-primary me-4" style={{ width: '4px', minHeight: '80px', borderRadius: '4px' }}></div>
      <Space direction="vertical" size={1} className="me-10">
        <Title className="fs-7" style={{ margin: 0 }}>
          {dayjs(appointment.start_time * 1000).format('HH:mm')} - {dayjs(appointment.end_time * 1000).format('HH:mm')}
        </Title>
        <Space>
          <Text className="fs-7 text-gray-600 fw-bold">Nhóm bệnh: </Text>
          <Tooltip
            title={appointment.disease_info.name}
          >
            <Text
              style={{ maxWidth: '250px' }}
              ellipsis={true}
            >
              <Link className="fs-7" to={`/disease/id/${appointment.disease_info.uid}`}>{appointment.disease_info.name}</Link>
            </Text>
          </Tooltip>
        </Space>
        <Space>
          <Text className="fs-7 text-gray-600 fw-bold">Bệnh nhân: </Text>
          <Tooltip
            title={appointment.patient_info.fullname}
          >
            <Text
              style={{ maxWidth: '250px' }}
              ellipsis={true}
            >
              <Link className="fs-7" to={`/patient/id/${appointment.patient_info.uid}`}>{appointment.patient_info.fullname}</Link>
            </Text>
          </Tooltip>
        </Space>
        <Space>
          <Text className="fs-7 text-gray-600 fw-bold">Lý do khám: </Text>
          <Text ellipsis className="fs-7" style={{ margin: 0, maxWidth: 200 }}>{appointment.reason}</Text>
        </Space>
      </Space>
      <Button className="ms-auto" onClick={() => navigate(`/calendar/id/${appointment.uid}`)}>Chi tiết</Button>
    </div>
  )
}

export const CalendarWeek = ({ appointments, currentDate, zoomMode }) => {
  const { token } = useToken()
  const calendarRef = useRef(undefined as any)
  const cellHeight = zoomMode === 'small' ? 80 : 200

  useEffect(() => {
    setTimeout(() => {
      if (calendarRef.current) {
        if (dayjs().isAfter(currentDate.weekday(0).startOf('day')) && dayjs().isBefore(currentDate.weekday(6).endOf('day'))) {
          console.log('current')
          calendarRef.current.scrollTo({ top: cellHeight * (dayjs().hour() > 3 ? dayjs().hour() - 3 : 0), behavior: 'smooth' })
        } else {
          const minHour = Math.min(...appointments.map(appointment => dayjs(appointment.start_time * 1000).hour()))
          calendarRef.current.scrollTo({ top: cellHeight * minHour, behavior: 'smooth' })
        }
      }
    })
  }, [appointments, currentDate, cellHeight])

  return (
    <>
      <Row className="w-100 border-bottom">
        <Col flex="50px"></Col>
        {Array.from(Array(7).keys()).map((index) =>
          <Col key={index} flex={1} style={{ flexBasis: 0 }}>
            <Row className="mx-auto border-end my-1" justify='center' align='middle' >
              <Space direction="vertical" align="center" size={0} style={{ lineHeight: '18px' }}>
                <Text style={{ fontSize: 10 }} strong>{currentDate.weekday(index).format('dddd').toUpperCase()}</Text>
                <Text strong style={{ color: token.colorPrimaryHover }}>{currentDate.weekday(index).format('DD')}</Text>
              </Space>
            </Row>
          </Col>
        )}
      </Row>

      <Row className="w-100" style={{ height: '100%', overflowY: 'scroll', marginTop: -1, scrollbarWidth: 'none' }} ref={calendarRef}>
        <Col flex="50px">
          {/* <Row style={{ height: cellHeight }} className="border-top" /> */}
          {Array.from(Array(24).keys()).map((hour) =>
            <Row key={hour} style={{ height: cellHeight }} justify='end' className="border-top border-start border-end">
              <span className="fs-8 text-gray-600 me-2">{dayjs().startOf('hour').hour(hour).format('HH:mm')}</span>
            </Row>)}
        </Col>
        {Array.from(Array(7).keys()).map((index) =>
          <Col key={index} flex={1}>
            {appointments.filter((appointment) => dayjs(appointment.start_time * 1000).weekday() === index)
              .map(appointment =>
                <Popover
                  key={appointment.uid}
                  content={<AppointmentInfo appointment={appointment} />}
                  // title="Lịch hẹn khám"
                  trigger='click'
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: cellHeight * dayjs(appointment.start_time * 1000).hour() + 3 + dayjs(appointment.start_time * 1000).minute() * cellHeight / 60,
                      width: '100%',
                      height: cellHeight / 4,
                    }}
                    role="button"
                  >
                    <div style={{
                      backgroundColor: '#eee',
                      borderRadius: '1px',
                      marginInline: 5,
                      paddingInline: 3,
                      lineHeight: '15px',
                      maxWidth: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      height: `${cellHeight / 4 - 5}px`
                    }}>
                      <div style={{ width: '4px', height: `${cellHeight / 4 - 10}px`, background: token.colorPrimary, borderRadius: '4px', marginRight: '3px', display: 'inline-block' }}></div>
                      {zoomMode === 'small' ?
                        <>
                          <span className="fs-8 text-gray-600" style={{ height: '15px', width: '45px', display: 'inline-block' }}> {dayjs(appointment.start_time * 1000).format('HH:mm')}</span>
                          <span className="fs-8 text-truncate text-gray-600" style={{ height: '15px', display: 'inline-block', width: '100%' }}>{appointment.patient_info.fullname}</span>
                        </>
                        :
                        <div style={{ width: '100%' }}>
                          <div style={{ display: 'flex', width: '100%' }}>
                            <span className="fs-8 text-gray-600" style={{ height: '15px', width: '45px', display: 'inline-block' }}> {dayjs(appointment.start_time * 1000).format('HH:mm')}</span>
                            <span className="fs-8 text-truncate text-gray-600" style={{ height: '15px', display: 'inline-block', width: '100%' }}>{appointment.patient_info.fullname}</span>
                          </div>
                          <span className="fs-8 text-truncate text-primary" style={{ height: '15px', display: 'inline-block', width: '100%' }}>{appointment.disease_info.name}</span>
                        </div>
                      }

                    </div>
                  </div>
                </Popover>
              )}
            {Array.from(Array(24).keys()).map((hour) =>
              <Row key={hour} style={{ height: cellHeight }} className="border-top border-end">
              </Row>)}
            {index === dayjs().weekday() && dayjs().isAfter(currentDate.weekday(0).startOf('day')) && dayjs().isBefore(currentDate.weekday(6).endOf('day')) &&
              <div style={{
                height: 2,
                position: 'absolute',
                width: '100%',
                top: cellHeight * dayjs().hour() + 3 + dayjs().minute() * 4 / 3,
                backgroundColor: `#f005`
              }}
                className="border-top border-end" >
              </div>}
          </Col>
        )}
      </Row >
    </>
  )
}