/* eslint-disable jsx-a11y/anchor-is-valid */
//@ts-nocheck
import {useEffect, useState} from 'react'
import {STEP_SUMMARY_API} from '../../../helpers/Common'
import axios from 'axios'
import dayjs from 'dayjs'
import {Button, DatePicker, Space} from 'antd'

import {Chart, Legend, TimeScale} from 'chart.js'
import {Bar} from 'react-chartjs-2'
import 'chartjs-adapter-dayjs-4'

Chart.register(
    TimeScale,
    Legend,
)

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            callbacks: {
                label: function(context) {
                    let label = ''
                    switch (context.datasetIndex) {
                        case 0: {
                            label += `Bước chân: ${context.raw.y} bước`
                            break
                        }
                    }
                    return label
                },
            },
        },
    },
    scales: {
        x: {
            type: 'time' as const,
            time: {
                unit: 'day' as const,
                displayFormats: {
                    day: 'D' as const,
                },
                round: 'day' as const,
                tooltipFormat: 'DD-MM-YYYY' as const,
            },
            title: {
                display: false,
            },
            ticks: {
                maxRotation: 0,
            },
        },
        y: {
            min: 0,
        },
    },
}

const StepMonthChart = ({className, uid}) => {
    let initData: any[] = []
    const [series, setSeries] = useState({datasets: initData})
    const [date, setDate] = useState(dayjs())

    const handleBack = () => {
        setDate(date.subtract(1, 'month'))
    }

    const handleNext = () => {
        setDate(date.add(1, 'month'))
    }

    const handleDateSelect = (date, dateString) => {
        setDate(date)
    }

    useEffect(() => {
        axios.get(STEP_SUMMARY_API, {
            params: {
                local_year: date.year(),
                local_month: date.month() + 1,
                target_user_id: uid,
            },
        })
            .then(response => {
                // let dateArr = new Array(date.endOf('month').date()).fill(0)
                // response.data.data.forEach(item => {
                //     dateArr[dayjs(item.ref_time).date() - 1] = item.total_steps
                // })
                let dateArr = new Array(date.endOf('month').date()).fill(0)
                response.data.data.forEach(item => {
                    dateArr[dayjs(item.ref_time).date() - 1] = item.total_steps
                })
                let data = dateArr.map((item, index) => {
                    return {
                        x: dayjs(date).date(index + 1).valueOf(),
                        y: item,
                    }
                })
                setSeries({
                    datasets: [{
                        data: data,
                        borderRadius: Number.MAX_VALUE,
                        // borderSkipped: false,
                        categoryPercentage: 0.4,
                        backgroundColor: '#3E97FF',
                    }],
                })
            })
            .catch((error => console.log(error)))
    }, [uid, date])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">Bước chân tháng</span>

                    <span className="text-muted fw-semibold fs-7"></span>
                </h3>

                {/* begin::Toolbar */}
                <div className="card-toolbar" data-kt-buttons="true">
                    <Space>
                        <Button type="default" onClick={handleBack}>Trước</Button>
                        <DatePicker
                            value={date} picker="month"
                            onChange={handleDateSelect}
                            getPopupContainer={(triggerNode): HTMLElement => {
                                return triggerNode.parentNode as HTMLElement
                            }}
                            allowClear={false}
                        />
                        <Button type="default" onClick={handleNext}>Sau</Button>
                    </Space>
                </div>
                {/* end::Toolbar */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className="card-body" style={{height: '500px'}}>
                <>(bước)</>
                <Bar
                    options={options}
                    data={series}
                />
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export {StepMonthChart}
