import { Button, Card, Col, Divider, Rate, Row, Space, Typography, notification } from 'antd';
import Avatar from 'antd/es/avatar/avatar';
import { useAuth } from 'app/modules/auth';
import { DISEASE_GROUP_DETAIL_API, getRelativeTime } from 'app/modules/helpers/Common';
import { Permission } from 'app/modules/helpers/Permission';
import axios from 'axios';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  checkUploadFileType,
  checkUploadFileSize
} from 'app/modules/apps/chatv3/ChatPage'

const { Text, Title } = Typography;

export const DiseaseHeader = ({ disease }) => {
  const uploadRef = useRef(null);
  const navigate = useNavigate();
  const { permission } = useAuth();
  const [showNotification, contextHolder] = notification.useNotification()

  let canUpdate = permission(Permission.UpdateDiseaseGroup)

  const changeAvatar = (e) => {
    console.log(e.target.files[0])

    const file = e.target.files[0];

    const typeAccepted = checkUploadFileType(file, 'image/*');
    const sizeAccepted = checkUploadFileSize([file], 15) || !typeAccepted;

    if (typeAccepted && sizeAccepted) {

      const formData = new FormData();
      formData.append("logo", e.target.files[0]);
      formData.append("disease_id", disease.uid)
      axios.put(DISEASE_GROUP_DETAIL_API, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          if (response.data.success) {
            navigate(0)
          }
        })


    } else {
      if (!typeAccepted) {
        showNotification.open({
          message: 'Lỗi',
          description: 'File không đúng định dạng',
          placement: 'topRight',
          icon: <i className='fa-solid fa-circle-info tw-text-red-500'></i>,
        })
      }

      if (!sizeAccepted) {
        showNotification.open({
          message: 'Lỗi',
          description: 'Tổng dung lượng File không được vượt quá 15MB',
          placement: 'topRight',
          icon: <i className='fa-solid fa-circle-info tw-text-red-500'></i>,
        })
      };

      /* @ts-ignore*/
      if (uploadRef?.current?.value) {
        /* @ts-ignore*/
        uploadRef.current.value = null;
      }


    }

  }

  return (
    <>
      <Row gutter={32}>
        {contextHolder}
        <Col span={8}>
          <Card style={{ width: '100%' }}>
            <Col>
              <Row justify='center'>
                <div className="symbol symbol-150px symbol-circle mb-7 w-125px h-125px">
                  <Avatar icon={<i className="fa-solid fa-disease"></i>} size={125} style={{ position: 'absolute' }} src={disease.logo || undefined} />
                  {
                    canUpdate &&
                    <>
                      <Row justify="end">
                        <Button style={{ borderRadius: 100 }} icon={<i className="fa-solid fa-pen"></i>} onClick={() => (uploadRef as any).current.click()} ></Button>
                      </Row>
                      <input type="file" name="avatar" accept=".png, .jpg, .jpeg" style={{ display: 'none' }} ref={uploadRef} onChange={changeAvatar} />
                    </>
                  }
                </div>
              </Row>
              <Row justify='center' className='mb-2'>
                <Rate value={disease?.rating} />
              </Row>
              <Row justify='center'>
                <Title level={4} >{disease?.name}</Title>
              </Row>
              <Row justify='center'>
                <div className="mb-3">
                  <div className="badge badge-lg badge-light-primary d-inline">Nhóm bệnh</div>
                </div>
              </Row>
            </Col>
            <Divider />
            <Space direction='vertical' style={{ width: "100%" }}>
              {/* <Space direction='vertical' size={[0, 0]}>
                <Text strong>Trạng thái tài khoản</Text>
                <Text className={org.is_active ? 'text-muted' : 'text-danger'}>{org.is_active ? 'Đang hoạt động' : 'Đã khoá'}</Text>
              </Space> */}

              <Space direction='vertical' size={[0, 0]}>
                <Text strong>Ngày tạo</Text>
                <Text className='text-muted'>{dayjs(disease.created_at * 1000).format('DD/MM/YYYY HH:mm:ss')} ({getRelativeTime(disease.created_at)})</Text>
              </Space>

              <Space direction='vertical' size={[0, 0]}>
                <Text strong>Trạng thái</Text>
                <Text className='text-muted'>{disease.registered ? 'Đã đăng ký' : 'Chưa đăng ký'}</Text>
              </Space>
            </Space>
          </Card>
        </Col>
        <Col span={16}>
          <Outlet />
        </Col>
      </Row>
    </>
  )
}
