import { Button, Card, Form, Input, InputNumber, Row, Space, Upload, notification } from "antd";
import {
  CloudUploadOutlined
} from '@ant-design/icons'
import { NEWSFEED_API } from "app/modules/helpers/Common";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useState, useCallback, useEffect
} from 'react'

import {
  checkUploadFileType,
  checkUploadFileSize
} from 'app/modules/apps/chatv3/ChatPage'

export const NewPost = () => {

  const { state } = useLocation();

  const [showNotification, contextHolder] = notification.useNotification()

  const navigate = useNavigate()

  const [form] = Form.useForm();

  const [fileList, setFileList] = useState<any[]>([]);

  const beforeUpload = useCallback((file) => {
    setFileList([file])
    return false
  }, [setFileList])

  const onRemove = useCallback((file) => {
    setFileList([])

  }, [setFileList])

  const normFile = (e: any) => {

    const fileList = Array.isArray(e) ? e : e.fileList;

    let res: any[] = [];

    res = fileList.slice(-1);

    /*
    / Check File for upload error
    /*/
    if (res.length) {
      const file = res[0];

      const typeAccepted = checkUploadFileType(file, 'image/*');
      const sizeAccepted = checkUploadFileSize([file], 15) || !typeAccepted;

      /*
         / Error handlers
         /*/
      if (!typeAccepted || !sizeAccepted) {
        res = fileList.filter(e => checkUploadFileSize([e], 15) && checkUploadFileType(e, 'image/*')).slice(-1);

        if (!typeAccepted) {
          showNotification.open({
            message: 'Lỗi',
            description: 'File không đúng định dạng',
            placement: 'topRight',
            icon: <i className='fa-solid fa-circle-info tw-text-red-500'></i>,
          })
        }

        if (!sizeAccepted) {
          showNotification.open({
            message: 'Lỗi',
            description: 'Tổng dung lượng File không được vượt quá 15MB',
            placement: 'topRight',
            icon: <i className='fa-solid fa-circle-info tw-text-red-500'></i>,
          })
        };
      }

    }


    return res;
  };

  const handleFormSubbmit = useCallback(() => {
    form.validateFields()
      .then((values) => {
        console.log(values);

        const { thumbnail } = values;

        const fieldList = ['title', 'url', 'order_number'];

        const optionalList = ['url'];

        /* @ts-ignore*/
        const { uid } = state || {};

        const data = new FormData();

        fieldList.forEach(f => {

          const v = values[f];

          if (typeof v !== 'undefined' && (optionalList.some(e => e.includes(f)) || typeof v === 'boolean' || typeof v === 'number' || v)) {
            data.append(f, v);
          }

        });

        if (thumbnail?.length) {
          data.append('thumbnail', thumbnail[0].originFileObj);
        }
        let cont;

        if (uid) {
          data.append('uid', uid);

          cont = axios.put(NEWSFEED_API, data)
        } else {
          cont = axios.post(NEWSFEED_API, data)

        }

        cont
          .then(response => {
            console.log(response);
            const { data: { success, error } } = response;

            if (success) {
              navigate(`/newsfeed/list`)
            } else {
              showNotification.open({
                message: 'Lỗi',
                description: error?.message,
                placement: 'topRight',
                icon: <i className='fa-solid fa-circle-info tw-text-red-500'></i>,

              })
            }
          })
      }).catch(err => {
        /* TODO: handle API error */
      });
  }, [form, navigate, showNotification, state])

  useEffect(() => {

    if (state) {
      /* @ts-ignore*/
      delete state.thumbnail;
      form.setFieldsValue(state);
    }

  }, [state, form])

  return (

    <>
      {contextHolder}
      <Card title={state ? 'Thông tin bài viết' : 'Thông tin bài viết mới'} actions={[
        <Row justify="end" style={{ paddingRight: 25 }}>
          <Space>
            <Button onClick={() => navigate(-1)}>Huỷ</Button>
            <Button type='primary' onClick={handleFormSubbmit}>{state ? 'Cập nhật' : 'Tạo bài viết'}</Button>
          </Space>
        </Row>
      ]} >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
        >
          <Form.Item label="Tiêu Đề" name="title" rules={[{ required: !!!state, whitespace: true, message: "Bắt buộc điền thông tin" }, { max: 256, message: "Tiêu đề không quá 256 kí tự" }]}>
            <Input onPaste={e => {

              setTimeout(() => {
                const v = form.getFieldValue('title') || '';
                form.setFieldValue('title', v.slice(0, 256));
                form.validateFields(['title'])
              }, 200)

            }} />
          </Form.Item>
          <Form.Item label="URL" name="url" rules={[{ required: !!!state, message: 'Bắt buộc điền thông tin' }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Thumbnail" name="thumbnail" rules={[{ required: !!!state, message: 'Bắt buộc Chọn ảnh' }]} valuePropName="fileList" getValueFromEvent={normFile}>
            <Upload beforeUpload={beforeUpload} fileList={fileList} accept={'image/*'} onRemove={onRemove}>
              <Button icon={<CloudUploadOutlined />}>Chọn ảnh</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="Thứ tự hiển thị" name="order_number" rules={[{ required: !!!state, message: 'Bắt buộc điền thông tin' }, { type: 'number', min: 0 }]}>
            <InputNumber />
          </Form.Item>

        </Form>
      </Card>

    </>

  )
}
