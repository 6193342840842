import { usePageData } from '_metronic/layout/core'
import { Breadcrumb, Typography, Tooltip } from 'antd'
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb'
import { FC, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { PageLink } from '_metronic/layout/core'
import { useAuth } from 'app/modules/auth'
import { Permission } from 'app/modules/helpers/Permission'


const { Text } = Typography

const DefaultTitle: FC = () => {

  const { permission } = useAuth();
  const { pathname } = useLocation();


  const { pageTitle, pageBreadcrumbs, pageDescription, setPageBreadcrumbs } = usePageData()

  useEffect(() => {

    let newBreadCrumbs: PageLink[] = pageBreadcrumbs || [];
    if (!permission(Permission.ViewAccountList)) {
      if (pageBreadcrumbs?.some(e => e.path.includes('account/list'))) {

        newBreadCrumbs = pageBreadcrumbs.map(e => {
          let res = e;
          if (e.path && e.path.includes('account/list')) {
            res = { ...e, path: pathname }
          }
          return res;
        })
      }
    }

    if (JSON.stringify(newBreadCrumbs) !== JSON.stringify(pageBreadcrumbs)) {
      setPageBreadcrumbs(newBreadCrumbs)
    }
  }, [permission, pathname, pageBreadcrumbs, setPageBreadcrumbs])

  let items: ItemType[] =
    pageBreadcrumbs
      ?.filter((item) => !item.isSeparator)
      .map((item) => ({
        title: (
          <Link className='text-primary fs-7 fw-bold' to={item.path}>
            {item.title}
          </Link>
        ),
      })) ?? []
  items.unshift({
    title: (
      <Link className='text-primary fs-7 fw-bold' to='/dashboard'>
        <i className='text-primary fa-solid fa-house fs-7'></i>
      </Link>
    ),
  })

  /* items.push({ title: <Text className='fs-7 fw-bold'>{pageTitle ?? ''}</Text> })*/
  items.push({
    title: <Tooltip title={pageTitle}>
      <Text className="fs-7" ellipsis={true}
        style={{ maxWidth: '250px' }}>
        <Text className='fs-7 fw-bold'>{pageTitle ?? ''}</Text>
      </Text>
    </Tooltip>
  })
  return (
    <div className='page-title d-flex justify-content-center flex-column me-5' style={{ height: 80 }}>
      <Breadcrumb items={items} separator={<i className='fa-solid fa-angle-right fs-7'></i>} />

      <Tooltip title={pageDescription || pageTitle}>
        <div className="tw-truncate"
          style={{ maxWidth: '250px' }}>
          <h1 className=' flex-column text-dark fw-bolder fs-2 mb-0 mt-1 tw-truncate'>
            {pageDescription || pageTitle}
          </h1>


        </div>
      </Tooltip>

    </div>
  )
}

export { DefaultTitle }
