import { Avatar, Col, Input, List, Row, Space, Typography, Card, Button, Tooltip } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs'
import { useEffect, useRef, useState, useCallback } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useFirebase } from '../../../Firebase'
import { CHATBOT_LIST_CONVERSATION, getShortTimeDescription } from '../../helpers/Common'

import { useDebounce } from 'use-debounce'

const { Text } = Typography
const preloadSize = 20

export const ChatBot = () => {
  const navigate = useNavigate()
  const { uid } = useParams()
  console.log('current conversation id', uid)

  const [conversationList, setConversationList] = useState([] as any[])
  const currentConversation = uid

  const { lastMessage, setLastMessage } = useFirebase()
  const [fetchCounter, setFetchCounter] = useState(0)

  const lastConversationTime = useRef(dayjs().unix() + 60)
  const moreConversationAvailable = useRef(true)

  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 300)

  const loadMore = useCallback(
    (info = {}, resetHistory = false) => {
      axios
        .get(CHATBOT_LIST_CONVERSATION, {
          params: {
            timestamp: lastConversationTime.current,
            limit: preloadSize,
            //my_own: true,

            ...info,
          },
        })
        .then((response) => response.data.data)
        .then((response) => {
          if (response.length < preloadSize) {
            moreConversationAvailable.current = false
          }

          let mergeHistory = [...conversationList, ...response]

          if (resetHistory) {
            mergeHistory = [...response]
          }
          let existingIds = new Set()
          let cleanHistory = mergeHistory.filter((el) => {
            const duplicate = existingIds.has(el.uid)
            existingIds.add(el.uid)
            return !duplicate
          })

          cleanHistory.sort((a, b) => b.updated_at - a.updated_at)

          if (JSON.stringify(conversationList) !== JSON.stringify(cleanHistory)) {
            setConversationList(cleanHistory)
          }

          if (response.length) {
            lastConversationTime.current = Math.ceil(response.at(-1).updated_at)
          }
        })
    },
    [setConversationList, conversationList]
  )

  const onScroll = (e) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight) {
      loadMore()
    }
    e.preventDefault()
  }

  const handleClick = (user) => {
    setFetchCounter(0)
    navigate(`/chatbot/id/${user.uid}`)
  }

  useEffect(() => {
    axios
      .get(CHATBOT_LIST_CONVERSATION, {
        params: {
          timestamp: dayjs().unix() + 60,
          limit: preloadSize,
          //my_own: true,

        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        if (response.length < preloadSize) {
          moreConversationAvailable.current = false
        }
        setConversationList([...response])
        lastConversationTime.current = Math.ceil(response.at(-1).updated_at)
      })
  }, [])

  useEffect(() => {
    if (conversationList && conversationList.length > 0) {
      if (!currentConversation) {
        navigate(`/chatbot/id/${conversationList[0].uid}`)
      }
    }
  }, [conversationList, currentConversation, navigate])

  useEffect(() => {
    if (debouncedSearch) {
      loadMore({ name: debouncedSearch, timestamp: dayjs().unix() }, true)
    } else {
      loadMore({ timestamp: dayjs().unix() + 60 })
    }
  }, [debouncedSearch, loadMore])

  useEffect(() => {
    if (lastMessage && lastMessage?.data?.message) {
      let newMessage = {
        ...lastMessage.data,
        type_message: 'text',
        last_message: JSON.parse(lastMessage.data.message),
        updated_at: dayjs().unix(),
      }

      axios
        .get(CHATBOT_LIST_CONVERSATION, {
          params: {
            timestamp: dayjs().unix() + 60,
            limit: preloadSize,
            conversation_type: 'INDIVIDUAL',
          },
        })
        .then((response) => response.data.data)
        .then((response) => {
          if (response.length < preloadSize) {
            moreConversationAvailable.current = false
          }

          let mergeHistory = [...conversationList, ...response]
          let existingIds = new Set()
          let cleanHistory = mergeHistory.filter((el) => {
            const duplicate = existingIds.has(el.uid)
            existingIds.add(el.uid)
            return !duplicate
          })

          setLastMessage(undefined)

          cleanHistory.forEach((conv) => {
            const { uid } = conv

            const { data: { conversation_id = '', message = '{}' } = {} } = lastMessage

            if (conversation_id && conversation_id === uid) {
              conv['last_message'] = JSON.parse(message)
              conv['updated_at'] = dayjs().unix()
            }
          })

          setConversationList(cleanHistory.sort((a, b) => b.updated_at - a.updated_at))
        })

      if (currentConversation === newMessage.conversation_id) {
        setFetchCounter((prev) => prev + 1)
      }
    }

    if (lastMessage && lastMessage?.data?.module === 'APPOINTMENT') {
      setLastMessage(undefined)
    }

  }, [lastMessage, setLastMessage, currentConversation, conversationList])

  return (
    <Row>
      <Col
        flex='265px'
        style={{
          backgroundColor: '#fff',
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
          overflowX: 'hidden',
          borderRight: '1px solid #f0f0f0',
        }}
      >
        <Row
          style={{ height: '50px', borderBottom: 1, borderColor: '#fff', marginInline: 20 }}
          align='middle'
          justify='center'
        >
          <Space>
            <Input.Search
              enterButton={
                <Button
                  type='link'
                  icon={<i className='fa-solid fa-magnifying-glass'></i>}
                ></Button>
              }
              placeholder='Tìm nhanh...'
              onInput={(e) => {
                /* @ts-ignore*/
                const name = e?.target?.value || ''
                setSearch(name)
              }}
            />
          </Space>
        </Row>
        {/* <Divider style={{ margin: 0 }} /> */}
        <div
          id='scrollableDiv'
          style={{ height: 'calc(100vh - 234px)', overflow: 'auto', scrollbarWidth: 'none' }}
          onScroll={onScroll}
        >
          <List>
            {conversationList.map((item, index) => (
              <List.Item
                style={{
                  paddingInline: 10,
                  border: 0,
                  backgroundColor: item.uid === currentConversation ? '#F6F8FC' : '#fff',
                }}
                key={index}
                onClick={() => handleClick(item)}
                role='button'
              >
                <Col flex='42px'>
                  {item.avatar ? (
                    <Avatar style={{ backgroundColor: '#1677FF' }} src={item.avatar}></Avatar>
                  ) : (
                    <Avatar style={{ backgroundColor: '#1677FF' }}>
                      {item.user_fullname.charAt(0)}
                    </Avatar>
                  )}
                </Col>
                <Col flex='143px'>
                  <Space.Compact direction='vertical'>
                    <Tooltip
                      title={item.user_fullname}
                    >
                      <Text className='fs-7 text-primary'
                        style={{ maxWidth: '143px' }}
                        ellipsis={true}
                      >{item.user_fullname}</Text>
                    </Tooltip>
                    <Text
                      className='text-gray-600 fs-8'
                      ellipsis={true}
                      style={{ maxWidth: '143px' }}
                    >
                      {item.type_message === 'text'
                        ? item.last_message.text
                        : item.type_message
                          ? 'Gửi 1 file cho bạn'
                          : ''}
                    </Text>
                  </Space.Compact>
                </Col>
                <Col flex='60px' style={{ textAlign: 'right' }}>
                  <Text className='fs-9 text-gray-600'>
                    {getShortTimeDescription(item.updated_at)}
                  </Text>
                </Col>
              </List.Item>
            ))}
          </List>
        </div>
      </Col>
      <Col flex='auto' style={{ height: 'calc(100vh - 234px + 50px)', flexGrow: 1, flexBasis: 0 }}>
        {uid ? (
          <Outlet context={[fetchCounter]} />
        ) : (
          <Card
            style={{
              height: 'calc(100vh - 234px + 50px)',
              marginLeft: 20,
              marginBlock: 20,
              flexGrow: 1,
              paddingTop: 0,
              margin: 0,
            }}
          ></Card>
        )}
      </Col>
    </Row>
  )
}
