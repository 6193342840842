import axios from 'axios'
import dayjs from 'dayjs'
import {useEffect, useState} from 'react'
import {SLEEP_SUMMARY_API} from '../../../helpers/Common'

import {Chart, Legend, TimeScale} from 'chart.js'
import 'chartjs-adapter-dayjs-4'

import {Button, DatePicker, Flex, Segmented, Space} from 'antd'

import {Line} from 'react-chartjs-2'

Chart.register(
    // CategoryScale,
    // LinearScale,
    TimeScale,
    // PointElement,
    // LineElement,
    // Title,
    // Tooltip,
    // ChartArea,
    Legend,
)

// const chartAreaBorder = {
//     id: 'chartAreaBorder',
//     beforeDraw(chart, args, options) {
//         const { ctx, chartArea: { left, top, width, height } } = chart;
//         ctx.save();
//         ctx.strokeStyle = options.borderColor;
//         ctx.lineWidth = options.borderWidth;
//         ctx.setLineDash(options.borderDash || []);
//         ctx.lineDashOffset = options.borderDashOffset;
//         ctx.strokeRect(left, top, width, height);
//         ctx.restore();
//     }
// };

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
            // position: 'top' as const,
        },
        tooltip: {
            enabled: false,
        },
        // chartAreaBorder: {
        //     borderColor: 'rgba(100,100,100,0.4)',
        //     borderWidth: 1,
        // borderDash: [5, 5],
        // borderDashOffset: 2,
        //   }
        // title: {
        //     display: true,
        //     text: 'Chart.js Line Chart',
        // },
    },
    // scales: {
    //     x: {
    //         type:
    //     }
    // }
    scales: {
        x: {
            type: 'time' as const,
            time: {
                unit: 'hour' as const,
                displayFormats: {
                    minute: 'HH' as const,
                    hour: 'HH:mm',
                },
            },
            title: {
                display: false,
                text: 'Date' as const,
            },
        },
        y: {
            ticks: {
                callback: function(value, index, ticks) {
                    switch (value) {
                        case -1:
                            return 'Thức'
                        case -4:
                            return 'REM'
                        case -7:
                            return 'Nông'
                        case -10:
                            return 'Sâu   '
                    }
                    // return '$' + value;
                },
                stepSize: 1,
            },
            min: -12,
            max: 1,
            type: 'linear' as const,
        },
    },
    parsing: {
        xAxisKey: 'x' as const,
        yAxisKey: 'y' as const,
    },
    animation: false as const,
    // plugins: [chartAreaBorder],
}

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//     // labels,
//     datasets: [
//         {
//             //   label: 'Dataset 1',
//             data: [65, 59, 80, 81, 56, 55],
//             //   borderColor: 'rgb(255, 99, 132)',
//             //   backgroundColor: 'rgba(255, 99, 132, 0.5)',
//         },
//         // {
//         //   label: 'Dataset 2',
//         //   data: [59, 80, 81, 56, 55, 40],
//         //   borderColor: 'rgb(53, 162, 235)',
//         //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
//         // },
//     ],
// };

const makeAnnotation = (series) => {
    // console.log(sleepData.light)
    let top = series.map(item => {
        return [{
            x: item.start_time_in_seconds * 1000,
            y: item.stage,
        },
            {
                x: item.end_time_in_seconds * 1000,
                y: item.stage,
            },
            {x: NaN, y: NaN},
        ]
    }).flat()

    let bottom = top.map(item => {
        return item ? {
            x: item.x,
            y: item.y - 2,
        } : null
    })

    return [top, bottom]
}

const SleepChart = ({className, uid}) => {
    let initData: any[] = []
    const [series, setSeries] = useState({datasets: initData})
    const [filter, setFilter] = useState('Tất cả')
    const [date, setDate] = useState(dayjs())
    // const [stats, setStats] = useState({})
    // console.log(series)

    const handleBack = () => {
        setDate(date.subtract(1, 'day'))
    }

    const handleNext = () => {
        setDate(date.add(1, 'day'))
    }

    const handleDateSelect = (date, dateString) => {
        setDate(date)
    }

    const handleFilter = (value) => {
        setFilter(value)
        console.log(value)
    }

    const cvtHour = (hours: string) => {
        return parseInt(hours.split(':')[0]) < 19 ? (parseInt(hours.split(':')[0]) + 24 + parseInt(hours.split(':')[1]) / 100) : (parseInt(hours.split(':')[0]) + parseInt(hours.split(':')[1]) / 100)
    }

    let legends = [['Ngủ sâu', 'rgba(62, 151, 255, 0.8)'],
        ['Ngủ nông', 'rgba(117, 204, 104, 0.8)'],
        ['REM', 'rgba(241, 65, 108, 0.8)'],
        ['Thức', 'rgba(255, 173, 15, 0.8)']]

    useEffect(() => {
        axios.get(SLEEP_SUMMARY_API, {params: {local_date: date.format('YYYY/MM/DD'), target_user_id: uid}})
            .then(response => response.data.data[0].sleep_levels_map)
            .then(sleepData => {
                let mergedData = [
                    sleepData.awake && sleepData.awake.map(item => {
                        item.stage = 0
                        return item
                    }),
                    sleepData.rem && sleepData.rem.map(item => {
                        item.stage = -3
                        return item
                    }),
                    sleepData.light && sleepData.light.map(item => {
                        item.stage = -6
                        return item
                    }),
                    sleepData.deep && sleepData.deep.map(item => {
                        item.stage = -9
                        return item
                    }),
                ].filter(item => item).flat().sort((a, b) => a.start_time_in_seconds - b.start_time_in_seconds)

                // console.log('final', mergedData)

                if (filter === 'Lọc' && mergedData.length > 0) {
                    // Smooth data
                    let smoothedData = [mergedData[0]]
                    // let current = mergedData[0].stage
                    // console.log('merge', mergedData)
                    for (let i = 1; i < mergedData.length; i++) {
                        if (i < mergedData.length - 1
                            && smoothedData[smoothedData.length - 1].stage === -3
                            && mergedData[i].stage === 0
                            && mergedData[i + 1].stage === -3) {
                            smoothedData[smoothedData.length - 1].end_time_in_seconds = mergedData[i + 1].end_time_in_seconds
                            i = i + 1
                        } else if (i < mergedData.length - 1
                            && smoothedData[smoothedData.length - 1].stage === -3
                            && mergedData[i].stage === -6
                            && mergedData[i + 1].stage === -3
                            && mergedData[i].end_time_in_seconds - mergedData[i].start_time_in_seconds < 60 * 10) {
                            smoothedData[smoothedData.length - 1].end_time_in_seconds = mergedData[i + 1].end_time_in_seconds
                            i = i + 1
                        } else {
                            smoothedData.push(mergedData[i])
                        }
                    }
                    mergedData = smoothedData
                }


                let dataLight = mergedData.filter(item => item.stage === -6)
                let dataRem = mergedData.filter(item => item.stage === -3)
                let dataDeep = mergedData.filter(item => item.stage === -9)
                let dataAwake = mergedData.filter(item => item.stage === 0)

                Promise.all([...Array(7).keys()].map(idx => {
                    return axios.get(SLEEP_SUMMARY_API, {
                        params: {
                            local_date: date.subtract(idx + 1, 'day').format('YYYY/MM/DD'),
                            target_user_id: uid,
                        },
                    })
                })).then(response => {
                    return response.map(res => {
                        return res.data.data.length > 0 ? res.data.data[0].sleep_levels_map : {}
                    })
                }).then(sleepMaps => {
                    let timeSleepBegin = sleepMaps.map(sleepMap => {
                        let sleepStage = Object.keys(sleepMap).map(key => sleepMap[key]).flat().map(item => item.start_time_in_seconds)
                        return sleepStage.length > 0 ? sleepStage.reduce((a, b) => Math.min(a, b)) : dayjs().valueOf() / 1000
                    })
                    timeSleepBegin = timeSleepBegin.map(item => dayjs(item * 1000).format('HH:mm'))

                    timeSleepBegin.sort((a, b) => cvtHour(a) - cvtHour(b))
                    // console.log(timeSleepBegin);
                    // console.log([Math.min(...timeSleepBegin), Math.max(...timeSleepBegin)].map(item=>dayjs(item*1000).format("HH:mm")).join(" - "))

                    // let totalSleepDuration = [...dataLight, ...dataRem, ...dataDeep, ...dataAwake].map((item) => {
                    //     return item.end_time_in_seconds - item.start_time_in_seconds
                    // }).reduce((a, b) => {
                    //     return a + b
                    // }) / 3600
                    // let numRemCycle = dataRem.length
                    // let totalRemSleepDuration = dataRem.map((item) => {
                    //     return item.end_time_in_seconds - item.start_time_in_seconds
                    // }).reduce((a, b) => {
                    //     return a + b
                    // }) / 3600

                    // setStats({
                    //     'Total-Sleep-Duration': {value: Math.floor(totalSleepDuration * 100) / 100, unit: 'h'},
                    //     'Num-Rem-Cycle': {value: numRemCycle, unit: 'cycle'},
                    //     'Total-Rem-Sleep-Duration': {value: Math.floor(totalRemSleepDuration * 100) / 100, unit: 'h'},
                    //     'Average-Time-Sleep-Start': {
                    //         value: [timeSleepBegin[0], timeSleepBegin[timeSleepBegin.length - 1]].join(' - '),
                    //         unit: 'h',
                    //     },
                    // })
                })


                let chartData = mergedData.map(item => {
                    return [{
                        // x: dayjs(item.start_time_in_seconds * 1000).format('HH:mm'),
                        x: item.start_time_in_seconds * 1000,
                        y: item.stage,
                    },
                        {
                            x: item.end_time_in_seconds * 1000,
                            y: item.stage,
                        },
                    ]
                }).flat()

                let chartBelow = chartData.map(item => {
                    return {
                        x: item.x,
                        y: item.y - 2,
                    }
                })

                const [lightMarkTop, lightMarkBelow] = makeAnnotation(dataLight)
                const [deepMarkTop, deepMarkBelow] = makeAnnotation(dataDeep)
                const [remMarkTop, remMarkBelow] = makeAnnotation(dataRem)
                const [awakeMarkTop, awakeMarkBelow] = makeAnnotation(dataAwake)

                // console.log(chartBelow)
                // console.log(chartData)
                setSeries({
                    datasets: [
                        {
                            data: chartData,
                            // stepped: true,
                            pointRadius: 0,
                            borderJoinStyle: 'bevel',
                            segment: {

                                // fill
                                // borderWidth: (context) => {
                                //     console.log(context)
                                //     if (context.p0DataIndex % 2 == 0) return 50
                                //     else return 1
                                // },
                            },
                            // fill: {
                            // target: '+1',
                            // above: 'rgba(0, 0, 250, 0.8)',
                            // },
                            borderColor: 'rgba(0, 0, 200, 0.5)',
                            borderWidth: 0.5,
                        },
                        {
                            data: chartBelow,
                            pointRadius: 0,
                            borderColor: 'rgba(0, 0, 200, 0.5)',
                            borderWidth: 0.5,
                        },
                        {
                            data: awakeMarkTop,
                            pointRadius: 0,
                            fill: {
                                target: '+1',
                                above: 'rgba(255, 173, 15, 0.8)',
                            },
                            borderWidth: 0,
                        },
                        {
                            data: awakeMarkBelow,
                            pointRadius: 0,
                            borderWidth: 0,
                        },
                        {
                            data: remMarkTop,
                            pointRadius: 0,
                            fill: {
                                target: '+1',
                                above: 'rgba(241, 65, 108, 0.8)',
                            },
                            borderWidth: 0,
                        },
                        {
                            data: remMarkBelow,
                            pointRadius: 0,
                            borderWidth: 0,
                        },
                        {
                            data: lightMarkTop,
                            pointRadius: 0,
                            fill: {
                                target: '+1',
                                above: 'rgba(117, 204, 104, 0.8)',
                            },
                            borderWidth: 0,
                        },
                        {
                            data: lightMarkBelow,
                            pointRadius: 0,
                            borderWidth: 0,
                        },
                        {
                            data: deepMarkTop,
                            pointRadius: 0,
                            fill: {
                                target: '+1',
                                above: 'rgba(62, 151, 255, 0.8)',
                            },
                            borderWidth: 0,
                        },
                        {
                            data: deepMarkBelow,
                            pointRadius: 0,
                            borderWidth: 0,
                        },
                    ],
                })
                // console.log(chartData)
            }).catch(error => {
            setSeries({datasets: []})
        })
    }, [uid, date, filter])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">Chất lượng giấc ngủ</span>

                    <span className="text-muted fw-semibold fs-7"></span>
                </h3>

                <div className="card-toolbar" data-kt-buttons="true">
                    <Space>
                        <Segmented options={['Lọc', 'Tất cả']} value={filter} onChange={handleFilter} />
                        <Button type="default" onClick={handleBack}>Trước</Button>
                        <DatePicker
                            value={date}
                            onChange={handleDateSelect}
                            getPopupContainer={(triggerNode): HTMLElement => {
                                return triggerNode.parentNode as HTMLElement
                            }}
                            allowClear={false}
                        />
                        <Button type="default" onClick={handleNext}>Sau</Button>
                    </Space>
                </div>
            </div>
            {/* end::Header */}

            {/* begin::Body */}

            <div className="card-body" style={{height: '500px'}}>
                <Flex style={{justifyContent: 'center'}}>
                    {legends.map((value, index) => {
                        return <Flex align="center" key={index}>
                            <div style={{
                                width: '20px',
                                height: '10px',
                                background: value[1],
                                marginLeft: '10px',
                                marginRight: '10px',
                            }}>
                            </div>
                            <span>
                                {value[0]}
                            </span>
                        </Flex>
                    })}
                </Flex>
                <Line
                    options={options}
                    data={series}
                    // plugins={[chartAreaBorder]}
                />
                {/* end::Chart */}
            </div>
            {/* end::Body */}
            {/*<div className='card-body'>*/}
            {/*    {Object.entries<any>(stats).map((item, index) => {*/}
            {/*        return (<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "40%" }} key={index}>*/}
            {/*            <h1 style={{ fontSize: "15px", color: "gray" }}>*/}
            {/*                {item[0].replaceAll("-", " ")} ({item[1].unit})*/}
            {/*            </h1>*/}
            {/*            <h1 style={{ fontSize: "15px" }}>*/}
            {/*                {item[1].value}*/}
            {/*            </h1>*/}
            {/*        </div>)*/}
            {/*    })}*/}
            {/*</div>*/}
        </div>
    )
}

export {SleepChart}
