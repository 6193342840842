import {useEffect, useState} from 'react'
import {BLOODPRESSURE_SUMMARY_API} from '../../../helpers/Common'
import axios from 'axios'
import dayjs from 'dayjs'
import {Button, DatePicker, Flex, Space} from 'antd'

import {Chart, Legend, TimeScale} from 'chart.js'
import {Line} from 'react-chartjs-2'
import 'chartjs-adapter-dayjs-4'
import {BloodPressureDetailModal} from './BloodPressureDetailModal'

Chart.register(
    TimeScale,
    Legend,
)

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            callbacks: {
                beforeLabel: function(context) {
                    return ''
                },
                label: function(context) {
                    let label = ''
                    switch (context.datasetIndex) {
                        case 0: {
                            label += `Tâm thu: ${context.raw.y} mmHg`
                            break
                        }
                        case 1: {
                            label += `Tâm trương: ${context.raw.y} mmHg`
                            break
                        }
                    }
                    return label
                },
                title: function() {
                    return ''
                },
            },
        },
    },
    scales: {
        x: {
            type: 'time' as const,
            time: {
                unit: 'day' as const,
                displayFormats: {
                    minute: 'HH' as const,
                    hour: 'ddd HH:mm' as const,
                    day: 'DD',
                },
                round: 'hour' as const,
                // tooltipFormat: 'DD T'
            },
            title: {
                display: false,
            },
        },
        y: {
            min: 0,
        },
    },
}

const BloodPressureChart = ({className, uid}) => {
    let initData: any[] = []
    const [series, setSeries] = useState({datasets: initData})
    const [date, setDate] = useState(dayjs())
    const [optionChart, setOptionChart] = useState(options)
    const [openDetailModal, setOpenDetailModal] = useState(false)

    const handleBack = () => {
        setDate(date.subtract(1, 'month'))
    }

    const handleNext = () => {
        setDate(date.add(1, 'month'))
    }

    const handleDateSelect = (date, dateString) => {
        setDate(date)
    }
    let legends = [['Tâm thu', '#3E97FF'],
        ['Tâm trương', '#75CC68']]

    useEffect(() => {
        axios.get(BLOODPRESSURE_SUMMARY_API, {
            params: {
                local_year: date.year(),
                local_month: date.month() + 1,
                target_user_id: uid,
            },
        })
            .then(response => {
                // let dateArr = new Array(date.endOf('month').date()).fill(null)
                // response.data.data.forEach(item => {
                //     dateArr[dayjs(item.ref_time).date() - 1] = item.avg_diastolic
                // })
                let diaData = response.data.data.map((item, index) => {
                    return {
                        x: dayjs(item.ref_time).valueOf(),
                        y: item.avg_diastolic,
                    }
                }).reverse()
                // dateArr = dateArr.fill(null)
                // response.data.data.forEach(item => {
                //     dateArr[dayjs(item.ref_time).date() - 1] = item.avg_systolic
                // })
                let sysData = response.data.data.map((item, index) => {
                    return {
                        x: dayjs(item.ref_time).valueOf(),
                        y: item.avg_systolic,
                    }
                }).reverse()

                // optionChart.scales.x['max'] = date.endOf('month').unix() * 1000;
                // optionChart.scales.x['min'] = date.startOf('month').unix() * 1000;
                // setOptionChart({...optionChart})
                setOptionChart({
                    ...options,
                    scales: {
                        ...options.scales,
                        x: {
                            ...options.scales.x,

                            // @ts-ignore
                            max: date.endOf('month').unix() * 1000,
                            min: date.startOf('month').unix() * 1000,
                        },
                    },
                })

                setSeries({
                    datasets: [{
                        data: diaData,
                        backgroundColor: '#3E97FF',
                        borderColor: '#3E97FF',
                        label: 'Tâm trương',
                    },
                        {
                            data: sysData,
                            backgroundColor: '#75CC68',
                            borderColor: '#75CC68',
                            label: 'Tâm thu',
                        }],
                })
            })
            .catch(error => console.log(error))
    }, [uid, date])

    return (
        <div className={`card ${className}`}>
            {openDetailModal && <BloodPressureDetailModal className={{}} uid={uid}
														  openDetailModal={openDetailModal}
														  closeDetailModal={() => setOpenDetailModal(false)}
			/>}
            {/* begin::Header */}
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">Huyết áp tháng</span>

                    <span className="text-muted fw-semibold fs-7"></span>
                </h3>

                {/* begin::Toolbar */}
                <div className="card-toolbar" data-kt-buttons="true">
                    <Space>
                        <Button type="default" onClick={handleBack}>Trước</Button>
                        <DatePicker value={date} picker="month" onChange={handleDateSelect} allowClear={false} />
                        <Button type="default" onClick={handleNext}>Sau</Button>
                    </Space>
                </div>
                {/* end::Toolbar */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className="card-body" style={{height: '500px'}}>
                <Flex style={{justifyContent: 'space-between'}}>
                    <div>(mmHg)</div>
                    <Flex style={{justifyContent: 'center'}}>
                        {legends.map((value, index) => {
                            return <Flex align="center" key={index}>
                                <div style={{
                                    width: '20px',
                                    height: '10px',
                                    background: value[1],
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                }}>
                                </div>
                                <span>
                                    {value[0]}
                                </span>
                            </Flex>
                        })}
                    </Flex>
                    <Button className={'py-0 h-20px'} type={'link'} onClick={() => setOpenDetailModal(true)}>Xem chi
                        tiết</Button>
                </Flex>
                <Line options={optionChart} data={series} />
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export {BloodPressureChart}
