/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Col, Divider, Row, Space, Typography, notification, Avatar } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
/* import { toAbsoluteUrl } from '../../../../../_metronic/helpers';*/
import { useAuth } from '../../../auth';
import { ORG_PICTURE_API, getRelativeTime } from '../../../helpers/Common';
import { Permission } from 'app/modules/helpers/Permission';

import {
  checkUploadFileType,
  checkUploadFileSize
} from 'app/modules/apps/chatv3/ChatPage'

const { Text, Title } = Typography;

const OrganizationHeader = ({ org }) => {
  const uploadRef = useRef(null);
  const navigate = useNavigate();
  const { permission } = useAuth();

  const [showNotification, contextHolder] = notification.useNotification()


  let isMyOrganization = false // auth?.organization.uid === org.uid
  let canChangeAvatar = permission(Permission.ChangeOrganizationInfo) || isMyOrganization

  const changeAvatar = (e) => {
    console.log(e.target.files[0])
    const file = e.target.files[0];

    const typeAccepted = checkUploadFileType(file, 'image/*');
    const sizeAccepted = checkUploadFileSize([file], 15) || !typeAccepted;

    if (typeAccepted && sizeAccepted) {

      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      if (!isMyOrganization) {
        formData.append("target_org_id", org.uid);
      }
      axios.put(ORG_PICTURE_API, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          if (response.data.success) {
            navigate(0)
          }
        })
    } else {
      if (!typeAccepted) {
        showNotification.open({
          message: 'Lỗi',
          description: 'File không đúng định dạng',
          placement: 'topRight',
          icon: <i className='fa-solid fa-circle-info tw-text-red-500'></i>,
        })
      }

      if (!sizeAccepted) {
        showNotification.open({
          message: 'Lỗi',
          description: 'Tổng dung lượng File không được vượt quá 15MB',
          placement: 'topRight',
          icon: <i className='fa-solid fa-circle-info tw-text-red-500'></i>,
        })
      };

      /* @ts-ignore*/
      if (uploadRef?.current?.value) {
        /* @ts-ignore*/
        uploadRef.current.value = null;
      }

    }

  }

  return (
    <>
      <Row gutter={32}>
        <Col span={8}>
          <Card style={{ width: '100%' }}>
            <Col>
              <Row justify='center'>
                {contextHolder}
                <div className="mb-7
                      tw-relative"

                >
                  <Avatar src={org.avatar ? org.avatar : '/media/avatars/blank.png'} size={128} />
                  {
                    canChangeAvatar &&
                    <>
                      <Row justify="end" className="tw-absolute tw-top-0 tw-right-0">
                        <Button style={{ borderRadius: 100 }} icon={<i className="fa-solid fa-pen"></i>} onClick={() => (uploadRef as any).current.click()} ></Button>
                      </Row>
                      <input type="file" name="avatar" accept=".png, .jpg, .jpeg" style={{ display: 'none' }} ref={uploadRef} onChange={changeAvatar} />
                    </>
                  }
                </div>
              </Row>
              <Row justify='center'>
                <Title level={4} >{org.name}</Title>
              </Row>
              <Row justify='center'>
                <div className="mb-3">
                  <div className="badge badge-lg badge-light-primary d-inline">Công ty đối tác</div>
                </div>
              </Row>
            </Col>
            <Divider />
            <Space direction='vertical' style={{ width: "100%" }}>
              <Space direction='vertical' size={[0, 0]}>
                <Text strong>Trạng thái tài khoản</Text>
                <Text className={org.is_active ? 'text-muted' : 'text-danger'}>{org.is_active ? 'Đang hoạt động' : 'Đã khoá'}</Text>
              </Space>

              <Space direction='vertical' size={[0, 0]}>
                <Text strong>Ngày tạo</Text>
                <Text className='text-muted'>{dayjs(org.created_at * 1000).format('DD/MM/YYYY HH:mm:ss')} ({getRelativeTime(org.created_at)})</Text>
              </Space>

              {/* <Space direction='vertical' size={[0, 0]}>
                <Text strong>Online</Text>
                <Text className='text-muted'>{dayjs(org.last_online_at * 1000).format('DD/MM/YYYY HH:mm:ss')} ({getRelativeTime(org.last_online_at)})</Text>
              </Space> */}
            </Space>
          </Card>
        </Col>
        <Col span={16}>
          <Outlet />
        </Col>
      </Row>
    </>
  )
}

export { OrganizationHeader };

