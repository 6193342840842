import { KTIcon, toAbsoluteUrl } from '_metronic/helpers'
import { ScrollTop } from '_metronic/layout/components/ScrollTop'
import { PageDataProvider } from '_metronic/layout/core'
import { Avatar, Layout, Menu, MenuProps, Row, Space, Typography, Result, Button, Tooltip } from 'antd'
import Sider from 'antd/es/layout/Sider'
import { Content, Header } from 'antd/es/layout/layout'
import { HeaderToolbar } from 'app/layout/HeaderToolbar'
import { useAuth } from 'app/modules/auth'
import { getRoleDescription } from 'app/modules/helpers/Common'
import { Permission } from 'app/modules/helpers/Permission'
import { useEffect, useState, useCallback, useRef } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import { MasterFooter } from './MasterFooter'
import clsx from 'clsx'

const { Text } = Typography

const MasterLayout = () => {

  const { pathname } = useLocation()

  const [error, setError] = useState(0);

  const previousPathname = useRef(pathname)

  const { auth, currentUser, permission } = useAuth()
  const [collapsed, setCollapsed] = useState(false)
  const navigate = useNavigate()

  const [headerStyle, setHeaderStyle] = useState("transparent")
  const [stateOpenKeys, _setStateOpenKeys] = useState<string[]>(['home']);
  const [selectedKeys, setSelectedKeys] = useState<string[]>(['home']);

  const setStateOpenKeys = useCallback((val) => {

    _setStateOpenKeys(val)

  }, [_setStateOpenKeys])

  const listenScrollEvent = (event) => {
    if (window.scrollY < 20) {
      return setHeaderStyle("transparent")
    } else if (window.scrollY >= 20) {
      return setHeaderStyle("white")
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  const updateMenuState = useCallback(() => {

    if (pathname) {
      const keys = [...stateOpenKeys];
      let patchKeys: string[] = [];

      if (pathname.includes('patient')) {
        setSelectedKeys(['bus-1']);
        patchKeys = ['bus'];
      } else if (pathname.includes('calendar/pending')) {
        setSelectedKeys(['cal-3']);
        patchKeys = ['cal'];
      } else if (pathname.includes('calendar/appointment')) {
        setSelectedKeys(['cal-1']);
        patchKeys = ['cal'];
      }


      const newKeys = patchKeys.filter(k => !keys.includes(k));

      if (newKeys.length) {
        setStateOpenKeys([...keys, ...newKeys])
        /* setStateOpenKeys([...patchKeys])*/
      }
    }


  }, [pathname, stateOpenKeys, setStateOpenKeys, setSelectedKeys])

  useEffect(() => {
    if (previousPathname.current !== pathname) {
      previousPathname.current = pathname
      updateMenuState()
    }

  }, [pathname, updateMenuState])

  useEffect(() => {

    if ([Permission.ShowSystemMenu, Permission.ShowOrganizationMenu].every(per => !permission(per)) && ['/disease/list', '/organization/id/me'].some(e => e.includes(pathname))) {
      navigate('/no-permission')
    }

  }, [pathname, permission, navigate, currentUser])

  const onOpenChange = useCallback((openKeys) => {

    const mainKeyList = ['bus', 'cal', 'sys', 'org'];

    const openList = mainKeyList.filter(k => openKeys.includes(k));

    const res = openKeys.filter(k => openList.some(parentKey => k.includes(parentKey)));

    const o = stateOpenKeys;

    if (JSON.stringify(o) !== JSON.stringify(res)) {
      setStateOpenKeys(res);
    }

  }, [setStateOpenKeys, stateOpenKeys])

  type MenuItem = Required<MenuProps>['items'][number];

  const itemClass = 'fs-2 text-primary'

  let items: MenuItem[] = [
    { key: 'home', icon: <span><KTIcon iconName='chart-pie-3' className={itemClass} /></span>, label: 'Tổng quan', onClick: () => navigate('/dashboard') },
    {
      key: 'bus', icon: <span><KTIcon iconName='notepad-edit' className={itemClass} /></span>, label: 'Nghiệp vụ', children: [
        { key: 'bus-1', icon: <span><KTIcon iconName='profile-user' className={itemClass} /></span>, label: 'Khách hàng', onClick: () => navigate('/patient/list', { state: { filter: 'ALL' } }) },
        ...([Permission.ShowSystemMenu, Permission.ShowOrganizationMenu].every(p => !permission(p)) ? [{ key: 'bus-2', icon: <span><KTIcon iconName='messages' className={itemClass} /></span>, label: 'Tin nhắn', onClick: () => navigate('/chat') }] : []),
        ...([Permission.ShowSystemMenu].some(p => permission(p)) ? [{ key: 'bus-5', icon: <span><KTIcon iconName='messages' className={itemClass} /></span>, label: 'Chatbot', onClick: () => navigate('/chatbot') }] : []),
        { key: 'bus-3', icon: <span><KTIcon iconName='questionnaire-tablet' className={itemClass} /></span>, label: 'Danh sách hẹn', onClick: () => navigate('/calendar/list') },
        { key: 'bus-4', icon: <span><KTIcon iconName='notification' className={itemClass} /></span>, label: 'Thông báo', onClick: () => navigate('/notification/list') },
        // { key: 'bus-4', icon: <span><KTIcon iconName='questionnaire-tablet' className='fs-2' /></span>, label: 'Danh sách hẹn (cũ)', onClick: () => navigate('/appointment/list') },
      ]
    }
  ]

  if (permission(Permission.ViewCalendar)) {
    items.push({
      key: 'cal', icon: <span><KTIcon iconName='calendar' className={itemClass} /></span>, label: 'Lịch', children: [
        { key: 'cal-1', icon: <span><KTIcon iconName='calendar-tick' className={itemClass} /></span>, label: 'Lịch hẹn', onClick: () => navigate('/calendar/appointment') },
        { key: 'cal-2', icon: <span><KTIcon iconName='time' className={itemClass} /></span>, label: 'Lịch làm việc', onClick: () => navigate('/calendar/work') },
        { key: 'cal-3', icon: <span><KTIcon iconName='double-up' className={itemClass} /></span>, label: 'Cần làm sớm', onClick: () => navigate('/calendar/pending') },
        // { key: 'cal-4', icon: <span><KTIcon iconName='time' className='fs-2' /></span>, label: 'Lịch làm việc', onClick: () => navigate('/schedule/plan') },
      ]
    })
  }

  if (permission(Permission.ShowSystemMenu)) {
    items.push({
      key: 'sys', icon: <span><KTIcon iconName='setting-3' className={itemClass} /></span>, label: 'Hệ thống', children: [
        { key: 'sys-1', icon: <span><KTIcon iconName='user-tick' className={itemClass} /></span>, label: 'Nhân viên', onClick: () => navigate('/account/list') },
        { key: 'sys-2', icon: <span><KTIcon iconName='brifecase-tick' className={itemClass} /></span>, label: 'Tổ chức', onClick: () => navigate('/organization/list') },
        { key: 'sys-3', icon: <span><KTIcon iconName='virus' className={itemClass} /></span>, label: 'Nhóm bệnh', onClick: () => navigate('/disease/list') },
        { key: 'sys-4', icon: <span><KTIcon iconName='ranking' className={itemClass} /></span>, label: 'Đánh giá', onClick: () => navigate('/rating/list') },
        { key: 'sys-5', icon: <span><KTIcon iconName='book' className={itemClass} /></span>, label: 'Bài viết', onClick: () => navigate('/newsfeed/list') },
        { key: 'sys-6', icon: <span><KTIcon iconName='design-frame' className={itemClass} /></span>, label: 'Banner', onClick: () => navigate('/banner/list') },
        { key: 'sys-7', icon: <span><KTIcon iconName='abstract-26' className={itemClass} /></span>, label: 'Tính năng', onClick: () => navigate('/feature/list') },
        { key: 'org-8', icon: <span><KTIcon iconName='cheque' className={itemClass} /></span>, label: 'Báo cáo', onClick: () => navigate('/system-report') },
      ]
    })
  }

  if (permission(Permission.ShowOrganizationMenu)) {
    items.push({
      key: 'org', icon: <span><KTIcon iconName='cube-2' className={itemClass} /></span>, label: 'Tổ chức', children: [
        { key: 'org-1', icon: <span><KTIcon iconName='user-tick' className={itemClass} /></span>, label: 'Nhân viên', onClick: () => navigate('/account/list') },
        { key: 'org-2', icon: <span><KTIcon iconName='brifecase-tick' className={itemClass} /></span>, label: 'Tổ chức', onClick: () => navigate('/organization/id/me') },
        { key: 'org-3', icon: <span><KTIcon iconName='virus' className={itemClass} /></span>, label: 'Nhóm bệnh', onClick: () => navigate('/disease/list') },
        { key: 'org-4', icon: <span><KTIcon iconName='questionnaire-tablet' className={itemClass} /></span>, label: 'Biểu mẫu', onClick: () => navigate('/form/list') },
        { key: 'org-5', icon: <span><KTIcon iconName='cheque' className={itemClass} /></span>, label: 'Báo cáo', onClick: () => navigate('/system-report') },
        { key: 'org-6', icon: <span><KTIcon iconName='ranking' className={itemClass} /></span>, label: 'Đánh giá', onClick: () => navigate('/rating/list') }
      ]
    })
  }

  useEffect(() => {
    if (!permission(Permission.ShowSystemMenu)) {
      if (['newsfeed', 'banner', 'feature'].some(e => pathname.includes(e))) {
        setError(403)

      } else {
        setError(0);
      }
    } else {
      setError(0);
    }

  }, [permission, pathname])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid overflow-y-hidden' >
        <Layout style={{ minHeight: '100vh' }} >
          <Sider
            collapsible
            theme='light'
            style={{ position: 'sticky', top: 0, left: 0, zIndex: 2 }}
            className='shadow-sm'
            collapsed={collapsed}
            onCollapse={(collapsed) => setCollapsed(collapsed)}
            width={265}
          >
            <Sider
              collapsible
              theme='light'
              style={{ height: 'calc(100vh - 2*1.25rem - 21px)', position: 'sticky', top: 0, left: 0, zIndex: 2 }}
              collapsed={collapsed}
              onCollapse={(collapsed) => setCollapsed(collapsed)}
              width={265}
            >
              <div style={{ overflowY: 'auto', height: '100%' }}>
                <Row>
                  <img src={toAbsoluteUrl('/media/logos/fpt.svg')} className='mx-auto mt-10' style={{ maxWidth: 60, width: '50%' }} alt='logo' />
                </Row>
                <Row className='pb-15 pt-10'>
                  <Space direction='vertical' align='center' className='mx-auto' size={0} hidden={collapsed}>
                    <Avatar src={currentUser?.avatar || '/media/avatars/blank.png'} size={64} className='mb-3' />
                    <Text className='text-gray-600 fw-bold d-block fs-8'>Xin chào, {getRoleDescription(auth?.role)}</Text>
                    <Tooltip title={[currentUser?.degree, currentUser?.fullname].filter(e => e).join('. ')}>
                      <Text className='text-primary text-hover-primary fs-6 fw-bold'
                        style={{ maxWidth: '250px' }}
                        ellipsis={true}
                      >{[currentUser?.degree, currentUser?.fullname].filter(e => e).join('. ')}</Text>
                    </Tooltip>
                  </Space>
                </Row>
                <Menu
                  defaultSelectedKeys={['home']}
                  defaultOpenKeys={['home']}
                  style={{ borderRight: 0 }}
                  mode="inline"
                  // theme="dark"
                  // inlineCollapsed={false} 
                  items={items}
                  openKeys={stateOpenKeys}
                  onOpenChange={onOpenChange}

                  selectedKeys={selectedKeys}
                  onSelect={({ item, key, keyPath, selectedKeys }) => { setSelectedKeys(selectedKeys) }}
                />
              </div>
            </Sider>
          </Sider>
          <Layout>
            <Header style={{ background: headerStyle, padding: 0, position: 'sticky', top: 0, zIndex: 1, height: 80 }} className={clsx({ 'border-bottom': headerStyle === 'white' })} >
              <HeaderToolbar />
            </Header>

            <Content>
              {error ? <Result
                status="403"
                title="403"
                subTitle="Xin lỗi, bạn không được phép truy cập trang này."
                extra={<Button type="primary" onClick={() => navigate('/dashboard')}>Quay về màn hình Tổng quan</Button>}
              /> : <Outlet />}

            </Content>

            <MasterFooter />
          </Layout>
        </Layout>
      </div>

      <ScrollTop />
    </PageDataProvider >
  )
}

export { MasterLayout }
