import {useEffect, useState} from 'react'
import {SLEEP_SUMMARY_API} from '../../../helpers/Common'
import axios from 'axios'
import dayjs from 'dayjs'

import {Chart, Legend, TimeScale} from 'chart.js'
import 'chartjs-adapter-dayjs-4'

import {Line} from 'react-chartjs-2'
import {KTIcon} from '../../../../../_metronic/helpers/components/KTIcon'
import {SleepChart} from './SleepChart'
import {useParams} from 'react-router-dom'

Chart.register(
  // CategoryScale,
  // LinearScale,
  TimeScale,
  // PointElement,
  // LineElement,
  // Title,
  // Tooltip,
  // ChartArea,
  Legend,
)

// const chartAreaBorder = {
//     id: 'chartAreaBorder',
//     beforeDraw(chart, args, options) {
//         const { ctx, chartArea: { left, top, width, height } } = chart;
//         ctx.save();
//         ctx.strokeStyle = options.borderColor;
//         ctx.lineWidth = options.borderWidth;
//         ctx.setLineDash(options.borderDash || []);
//         ctx.lineDashOffset = options.borderDashOffset;
//         ctx.strokeRect(left, top, width, height);
//         ctx.restore();
//     }
// };

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      // position: 'top' as const,
    },
    tooltip: {
      enabled: false,
    },
    // chartAreaBorder: {
    //     borderColor: 'rgba(100,100,100,0.4)',
    //     borderWidth: 1,
    // borderDash: [5, 5],
    // borderDashOffset: 2,
    //   }
    // title: {
    //     display: true,
    //     text: 'Chart.js Line Chart',
    // },
  },
  // scales: {
  //     x: {
  //         type:
  //     }
  // }
  scales: {
    x: {
      type: 'time' as const,
      time: {
        unit: 'hour' as const,
        displayFormats: {
          minute: 'HH' as const,
          hour: 'HH:mm',
        },
      },
      title: {
        display: false,
        text: 'Date' as const,
      },
      ticks: {
        // Xoay các nhãn trục X
        autoSkip: false, // Nếu bạn không muốn bỏ qua nhãn khi trục quá dài
        maxRotation: 0, // Góc xoay tối đa
        minRotation: 0,  // Góc xoay tối thiểu
      },
    },
    y: {
      ticks: {
        callback: function(value, index, ticks) {
          switch (value) {
            case -1:
              return 'Thức'
            case -4:
              return 'REM'
            case -7:
              return 'Nông'
            case -10:
              return 'Sâu'
          }
          // return '$' + value;
        },
        stepSize: 1,
      },
      min: -12,
      max: 1,
      type: 'linear' as const,
    },
  },
  parsing: {
    xAxisKey: 'x' as const,
    yAxisKey: 'y' as const,
  },
  animation: false as const,
  // plugins: [chartAreaBorder],
}

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//     // labels,
//     datasets: [
//         {
//             //   label: 'Dataset 1',
//             data: [65, 59, 80, 81, 56, 55],
//             //   borderColor: 'rgb(255, 99, 132)',
//             //   backgroundColor: 'rgba(255, 99, 132, 0.5)',
//         },
//         // {
//         //   label: 'Dataset 2',
//         //   data: [59, 80, 81, 56, 55, 40],
//         //   borderColor: 'rgb(53, 162, 235)',
//         //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
//         // },
//     ],
// };

const makeAnnotation = (series) => {
  // console.log(sleepData.light)
  let top = series.map(item => {
    return [{
      x: item.start_time_in_seconds * 1000,
      y: item.stage,
    },
      {
        x: item.end_time_in_seconds * 1000,
        y: item.stage,
      },
      {x: NaN, y: NaN},
    ]
  }).flat()

  let bottom = top.map(item => {
    return item ? {
      x: item.x,
      y: item.y - 2,
    } : null
  })

  return [top, bottom]
}

const SleepWidget = ({className, uid, chartRef = {} as any}) => {
  let initData: any[] = []
  const [series, setSeries] = useState({datasets: initData})
  const [filter] = useState('Tất cả')
  const [date] = useState(dayjs())
  const [showModal, setShowModal] = useState(false)
  const [modalWidget, setModalWidget] = useState(<SleepChart uid={uid} className="card-xxl-stretch mb-5 mb-xl-10" />)
  const {uid: uid_} = useParams()
  // console.log(series)

  // const handleBack = () => {
  //     setDate(date.subtract(1, 'day'))
  // }

  // const handleNext = () => {
  //     setDate(date.add(1, 'day'))
  // }

  // const handleDateSelect = (date, dateString) => {
  //     setDate(date)
  // }

  // const handleFilter = (value) => {
  //     setFilter(value)
  //     console.log(value)
  // }


  useEffect(() => {
    axios.get(SLEEP_SUMMARY_API, {params: {local_date: date.format('YYYY/MM/DD'), target_user_id: uid_}})
      .then(response => response.data.data[0].sleep_levels_map)
      .then(sleepData => {
        let mergedData = [
          sleepData.awake && sleepData.awake.map(item => {
            item.stage = 0
            return item
          }),
          sleepData.rem && sleepData.rem.map(item => {
            item.stage = -3
            return item
          }),
          sleepData.light && sleepData.light.map(item => {
            item.stage = -6
            return item
          }),
          sleepData.deep && sleepData.deep.map(item => {
            item.stage = -9
            return item
          }),
        ].filter(item => item).flat().sort((a, b) => a.start_time_in_seconds - b.start_time_in_seconds)

        console.log('final', mergedData)

        if (mergedData.length > 0) {
          // Smooth data
          let smoothedData = [mergedData[0]]
          // let current = mergedData[0].stage
          console.log('merge', mergedData)
          for (let i = 1; i < mergedData.length; i++) {
            if (i < mergedData.length - 1
              && smoothedData[smoothedData.length - 1].stage === -3
              && mergedData[i].stage === 0
              && mergedData[i + 1].stage === -3) {
              smoothedData[smoothedData.length - 1].end_time_in_seconds = mergedData[i + 1].end_time_in_seconds
              i = i + 1
            } else if (i < mergedData.length - 1
              && smoothedData[smoothedData.length - 1].stage === -3
              && mergedData[i].stage === -6
              && mergedData[i + 1].stage === -3
              && mergedData[i].end_time_in_seconds - mergedData[i].start_time_in_seconds < 60 * 10) {
              smoothedData[smoothedData.length - 1].end_time_in_seconds = mergedData[i + 1].end_time_in_seconds
              i = i + 1
            } else {
              smoothedData.push(mergedData[i])
            }
          }
          mergedData = smoothedData
        }

        let dataLight = mergedData.filter(item => item.stage === -6)
        let dataRem = mergedData.filter(item => item.stage === -3)
        let dataDeep = mergedData.filter(item => item.stage === -9)
        let dataAwake = mergedData.filter(item => item.stage === 0)

        let chartData = mergedData.map(item => {
          return [{
            // x: dayjs(item.start_time_in_seconds * 1000).format('HH:mm'),
            x: item.start_time_in_seconds * 1000,
            y: item.stage,
          },
            {
              x: item.end_time_in_seconds * 1000,
              y: item.stage,
            },
          ]
        }).flat()

        let chartBelow = chartData.map(item => {
          return {
            x: item.x,
            y: item.y - 2,
          }
        })

        const [lightMarkTop, lightMarkBelow] = makeAnnotation(dataLight)
        const [deepMarkTop, deepMarkBelow] = makeAnnotation(dataDeep)
        const [remMarkTop, remMarkBelow] = makeAnnotation(dataRem)
        const [awakeMarkTop, awakeMarkBelow] = makeAnnotation(dataAwake)

        // console.log(chartBelow)
        // console.log(chartData)
        setSeries({
          datasets: [
            {
              data: chartData,
              // stepped: true,
              pointRadius: 0,
              borderJoinStyle: 'bevel',
              segment: {

                // fill
                // borderWidth: (context) => {
                //     console.log(context)
                //     if (context.p0DataIndex % 2 == 0) return 50
                //     else return 1
                // },
              },
              // fill: {
              // target: '+1',
              // above: 'rgba(0, 0, 250, 0.8)',
              // },
              borderColor: 'rgba(0, 0, 200, 0.5)',
              borderWidth: 0.5,
            },
            {
              data: chartBelow,
              pointRadius: 0,
              borderColor: 'rgba(0, 0, 200, 0.5)',
              borderWidth: 0.5,
            },
            {
              data: awakeMarkTop,
              pointRadius: 0,
              fill: {
                target: '+1',
                above: 'rgba(255, 173, 15, 0.8)',
              },
              borderWidth: 0,
            },
            {
              data: awakeMarkBelow,
              pointRadius: 0,
              borderWidth: 0,
            },
            {
              data: remMarkTop,
              pointRadius: 0,
              fill: {
                target: '+1',
                above: 'rgba(241, 65, 108, 0.8)',
              },
              borderWidth: 0,
            },
            {
              data: remMarkBelow,
              pointRadius: 0,
              borderWidth: 0,
            },
            {
              data: lightMarkTop,
              pointRadius: 0,
              fill: {
                target: '+1',
                above: 'rgba(117, 204, 104, 0.8)',
              },
              borderWidth: 0,
            },
            {
              data: lightMarkBelow,
              pointRadius: 0,
              borderWidth: 0,
            },
            {
              data: deepMarkTop,
              pointRadius: 0,
              fill: {
                target: '+1',
                above: 'rgba(62, 151, 255, 0.8)',
              },
              borderWidth: 0,
            },
            {
              data: deepMarkBelow,
              pointRadius: 0,
              borderWidth: 0,
            },
          ],
        })
      }).catch(error => {
      setSeries({datasets: []})
    })
  }, [uid_, date, filter])

  useEffect(() => {
    if (showModal) {
      setModalWidget(<SleepChart uid={uid} className="card-xxl-stretch mb-5 mb-xl-10" />)
    } else {
      setModalWidget(<></>)
    }
  }, [showModal, uid])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Chất lượng giấc ngủ</span>
          <span
            className="text-muted fw-semibold fs-7">{date.subtract(1, 'day').format('DD/MM/YYYY')} - {date.format('DD/MM/YYYY')}</span>
        </h3>

        <div className="card-toolbar" data-kt-buttons="true">
          {/* <Space> */}
          {/* <Segmented options={['Lọc', 'Tất cả']} value={filter} onChange={handleFilter} /> */}
          {/* <Button type="default" onClick={handleBack}>Trước</Button>
                        <DatePicker value={date} onChange={handleDateSelect} allowClear={false} />
                        <Button type="default" onClick={handleNext}>Sau</Button> */}
          {/* </Space> */}
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-bs-toggle="modal" data-bs-target="#kt_modal_2"
            onClick={() => {
              setShowModal(true)
            }}
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>

          <div className="modal modal-xl fade" tabIndex={-1} id="kt_modal_2">
            <div className="modal-dialog">
              <div className="modal-content">
                {/* <div className="modal-header">
                                    <h3 className="modal-title">Modal title</h3>
                                    <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                        <i className="ki-duotone ki-cross fs-1"><span className="path1"></span><span className="path2"></span></i>
                                    </div>
                                </div> */}

                <div className="modal-body">
                  {modalWidget}
                </div>

                <div className="modal-footer">
                  <button type="button" className="btn" style={{color: '#FFFFFF', backgroundColor: '#3e97ff'}}
                          data-bs-dismiss="modal" onClick={() => {
                    setShowModal(false)
                  }}>Đóng
                  </button>
                  {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body" style={{height: '300px'}}>
        <Line
          options={options}
          data={series}
          ref={chartRef}
          // plugins={[chartAreaBorder]}
        />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {SleepWidget}
