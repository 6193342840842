import {useEffect, useState} from 'react'
import {BLOODGLUCOSE_SUMMARY_API} from '../../../helpers/Common'
import axios from 'axios'
import dayjs from 'dayjs'
import {Button, DatePicker, Dropdown, Flex, MenuProps, Space} from 'antd'
import {DownOutlined} from '@ant-design/icons'
import {Chart, Legend, TimeScale} from 'chart.js'
import {Line} from 'react-chartjs-2'
import 'chartjs-adapter-dayjs-4'
import {BloodGlucoseDetailModal} from './BloodGlucoseDetailModal'

Chart.register(
    TimeScale,
    Legend,
)

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            // ticks: { count: 12 },
            type: 'time' as const,
            time: {
                unit: 'day' as const,
                displayFormats: {
                    minute: 'HH' as const,
                    hour: 'ddd HH:mm' as const,
                    day: 'DD',
                },
                round: 'hour' as const,
                // tooltipFormat: 'DD T'
            },
            title: {
                display: false,
            },
        },
        y: {
            min: 0,
        },
    },
}

const BloodGlucoseChart = ({className, uid}) => {
    let initData: any[] = []

    enum Unit {
        MMOL = '(mmol/L)',
        MGDL = '(mg/dL)'
    }

    const [series, setSeries] = useState({datasets: initData})
    const [date, setDate] = useState(dayjs())
    const [optionChart, setOptionChart] = useState(options)
    const [unit, setUnit] = useState(Unit.MMOL)
    const [openDetailModal, setOpenDetailModal] = useState(false)

    const handleBack = () => {
        setDate(date.subtract(1, 'month'))
    }

    const handleNext = () => {
        setDate(date.add(1, 'month'))
    }

    const handleDateSelect = (date, dateString) => {
        setDate(date)
    }


    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div onClick={() => setUnit(Unit.MMOL)}>
                    {Unit.MMOL}
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div onClick={() => setUnit(Unit.MGDL)}>
                    (mg/dL)
                </div>
            ),
        },
    ]

    let legends = [['Trước ăn', '#FFF', '#F1416C'],
        ['Sau ăn', '#F1416C', '#FFF']]

    useEffect(() => {
        axios.get(BLOODGLUCOSE_SUMMARY_API, {
            params: {
                local_year: date.year(),
                local_month: date.month() + 1,
                target_user_id: uid,
            },
        })
            .then(response => response.data.data)
            .then(data => {
                // console.log('bs', data)
                // let dateArr = new Array(date.endOf('month').date()).fill(null)
                // data.forEach(item => {
                //     dateArr[dayjs(item.ref_time).date() - 1] = item.avg_blood_glucose
                // })
                const be: any[] = []
                const ae: any[] = []
                data.reverse().map((item, index) => {
                    const yValue = unit === Unit.MGDL ? item.avg_blood_glucose : item.avg_blood_glucose_mmol
                    if (item.reference === 'BEFORE_EATING') {
                        be.push({
                            x: dayjs(item.ref_time).valueOf(),
                            y: yValue,
                        })
                    } else {
                        ae.push({
                            x: dayjs(item.ref_time).valueOf(),
                            y: yValue,
                        })
                    }
                    return ({
                        x: dayjs(item.ref_time).valueOf(),
                        y: yValue,
                    })
                })
                setOptionChart({
                    ...options,
                    scales: {
                        ...options.scales,
                        x: {
                            ...options.scales.x,

                            // @ts-ignore
                            max: date.endOf('month').unix() * 1000,
                            min: date.startOf('month').unix() * 1000,
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                            position: 'top',
                            align: 'center',
                            labels: {
                                color: 'darkred',
                                font: {
                                    weight: 'bold',
                                },
                            },
                        },
                        tooltip: {
                            callbacks: {
                                beforeLabel: function(context) {
                                    return ''
                                },
                                label: function(context) {
                                    return `Đường huyết: ${context.raw.y} ${unit}`
                                },
                                title: function() {
                                    return ''
                                },
                            },
                        },
                    },
                })
                setSeries({
                    datasets: [{
                        data: be,
                        type: 'line',
                        // backgroundColor: '#75CC68',
                        borderColor: '#F1416C',
                        pointBackgroundColor: '#FFF',
                        // pointBackgroundColor: markerData,
                    },
                        {
                            type: 'bubble',
                            data: ae,
                            // backgroundColor: '#75CC68',
                            borderColor: '#F1416C',
                            pointBackgroundColor: '#F1416C',
                        },
                    ],
                })
            })
            .catch(error => console.log(error))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid, date, unit])

    return (
        <div className={`card ${className}`}>
            {openDetailModal &&
				<BloodGlucoseDetailModal className={{}} uid={uid}
										 openDetailModal={openDetailModal}
										 closeDetailModal={() => setOpenDetailModal(false)}
				/>
            }
            {/* begin::Header */}
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">Đường huyết tháng</span>

                    <span className="text-muted fw-semibold fs-7"></span>
                </h3>

                {/* begin::Toolbar */}
                <div className="card-toolbar" data-kt-buttons="true">
                    <Space>
                        <Button type="default" onClick={handleBack}>Trước</Button>
                        <DatePicker value={date} picker="month" onChange={handleDateSelect} allowClear={false} />
                        <Button type="default" onClick={handleNext}>Sau</Button>
                    </Space>
                </div>
                {/* end::Toolbar */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className="card-body" style={{height: '600px'}}>

                <Flex style={{justifyContent: 'space-between'}}>
                    <><Dropdown menu={{items}}>
                        <Button style={{height: 20, padding: 0}} type={'text'} iconPosition={'end'}
                                icon={<DownOutlined />}>{unit}</Button></Dropdown></>
                    <Flex style={{justifyContent: 'center'}}>
                        {legends.map((value, index) => {
                            return <Flex align="center" key={index}>
                                <div style={{
                                    width: '20px',
                                    height: '10px',
                                    background: value[1],
                                    borderStyle: 'solid',
                                    borderWidth: 1,
                                    borderColor: value[2],
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                }}>
                                </div>
                                <span>
                                    {value[0]}
                                </span>
                            </Flex>
                        })}
                    </Flex>
                    <Button className={'py-0 h-20px'} type={'link'} onClick={() => setOpenDetailModal(true)}>Xem chi
                        tiết</Button>
                </Flex>
                <Line options={optionChart} data={series} />
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export {BloodGlucoseChart}
