import { Button, Card, ConfigProvider, Table, Typography, Tooltip } from "antd"
import Column from "antd/es/table/Column"
import { APPOINTMENT_PENDING_API, getAppointmentStatusDescription, getRelativeTime } from "app/modules/helpers/Common"
import axios from "axios"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

const { Text } = Typography

export const PendingTaskPage = () => {
  const [appointments, setAppointments] = useState([] as any)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [total, setTotal] = useState(0)
  const navigate = useNavigate()
  const pageSize = 10

  useEffect(() => {
    axios.get(APPOINTMENT_PENDING_API, { params: { page: page, per_page: pageSize } })
      .then(response => response.data.data)
      .then(data => {
        setTotal(data.total)
        setAppointments(data.items)
        setMaxPage(data.max_page)
      })
  }, [page])

  return (
    <Card title={`Danh sách công việc (${total})`}>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: '#fff',
            }
          },
        }}>
        <Table
          dataSource={appointments}
          rowKey="uid"
          pagination={{ defaultCurrent: 1, total: maxPage * pageSize, showSizeChanger: false, onChange: (page) => setPage(page), current: page }}
          onHeaderRow={() => ({ className: 'fw-bolder fs-7 text-uppercase' })}
          onRow={() => ({ className: 'fs-7 text-gray-600' })}
          size='small'
          locale={{ emptyText: 'Không có dữ liệu' }}
        >
          <Column title="Khách hàng" dataIndex="patient_info" render={value => <Tooltip title={value.fullname}>
            <Text className="fs-7" ellipsis={true}
              style={{ maxWidth: '300px' }}>
              <Text className='fs-7'>{value.fullname ?? ''}</Text>
            </Text>
          </Tooltip>} />
          <Column title="Thời gian" dataIndex="start_time" render={(value) => dayjs(value * 1000).format('DD/MM/YYYY HH:mm')} responsive={['xl']} />
          <Column title="Nhóm bệnh" dataIndex={['disease_info', 'name']} responsive={['lg']} />
          <Column title="Ngày tạo" dataIndex="created_at" render={(value) => getRelativeTime(value)} responsive={['xl']} />
          <Column title="Trạng thái" dataIndex="status"
            render={(value) => {
              const [description, subtitle] = getAppointmentStatusDescription(value)
              return <span className="fs-7 text-danger">{description}{subtitle && ` [${subtitle}]`}</span>
            }} />
          <Column title="Thao tác" align="end" render={(_, record: any) =>
            <Button className="fs-7" onClick={() => {
              navigate(`/calendar/id/${record.uid}/conclusion`)
            }}>Xử lý
            </Button>}
          />
        </Table>
      </ConfigProvider>
    </Card>
  )
}
