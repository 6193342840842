import { PageTitle } from '_metronic/layout/core'
import { Route, Routes } from 'react-router-dom'
import { ChatWidgetWrapper } from './ChatWidgetWrapper'

import {
    ChatBot
} from 'app/modules/apps/chat/ChatBarWithBot'

export const ChatBotRoutes = () => {
    return (
        <Routes>
            <Route element={
                <>
                    <PageTitle description='Danh sách tin nhắn'>Tin nhắn</PageTitle>
                    <ChatBot />
                </>
            }>
                <Route
                    path='id/:uid/*'
                    element={<ChatWidgetWrapper />}
                />
                <Route index element={<></>} />
            </Route>
        </Routes>



    )
}
